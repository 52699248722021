
import { defineComponent, reactive, toRefs } from 'vue'
import { useMutation } from '@vue/apollo-composable'
import * as gql from '@/graphql'
import { ElMessage } from 'element-plus'
import storage, { StorageType } from '@/utils/storage'
import { useRouter } from 'vue-router'
import webSocket from '@/mqtt'
import paramsWrap from '@/apollo-client/paramsWrap'
export default defineComponent({
  name: 'RevisePwd',
  props: {
    dialogVisible: { type: Boolean, default: false },
    managerId: {
      type: String,
      default: ''
    },
    clientId: {
      type: String,
      default: 'default'
    }
  },
  emits: ['update:dialogVisible', 'confirm'],
  setup(props, { emit }) {
    const router = useRouter()
    const state = reactive({
      pwdOld: '',
      pwdNew: '',
      pwdNew2: '',
      loading: false
    })
    const { mutate: revisePwd } = useMutation(gql.lpoaManagerCpwd, {
      clientId: props.clientId
    })
    // 退出登录
    const { mutate: logout } = useMutation(gql.lpoaLogoutManager)
    const close = () => {
      state.pwdNew = ''
      state.pwdNew2 = ''
      state.pwdOld = ''
      emit('update:dialogVisible', false)
    }
    const confirm = () => {
      if (!state.pwdOld) {
        ElMessage({
          message: '原密码不能为空',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      if (!state.pwdNew) {
        ElMessage({
          message: '新密码不能为空',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      if (!state.pwdNew2) {
        ElMessage({
          message: '确认密码不能为空',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      if (state.pwdNew && state.pwdNew !== state.pwdNew2) {
        ElMessage({
          message: '两次密码输入不一致',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      state.loading = true
      revisePwd({
        params: {
          managerId: props.managerId,
          pwdOld: paramsWrap(state.pwdOld),
          pwdNew: paramsWrap(state.pwdNew)
        }
      }).then(res => {
        const { lpoaManagerCpwd } = res?.data
        if (lpoaManagerCpwd.ok) {
          ElMessage({
            message: 'LPOA密码修改成功，请重新登录',
            type: 'success',
            customClass: 'custom-success'
          })
          storage.rcRemoveItem(StorageType.session, 'unlock')
          logout().then((res: any) => {
            if (res.data.lpoaLogoutManager.ok) {
              webSocket.close()
              router.push({
                name: 'Login'
              })
              window.localStorage.clear()
              window.sessionStorage.clear()
              router.push('/login')
            }
          })
          state.loading = false
          emit('update:dialogVisible', false)
          return
        }
        ElMessage({
          message: lpoaManagerCpwd.reason.desc,
          type: 'error',
          customClass: 'custom-error'
        })
        state.loading = false
      })
    }

    return {
      ...toRefs(state),
      close,
      confirm
    }
  }
})
