import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b6699834"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["sidebar-logo-container", [_ctx.collapse ? 'collapse': 'notitle']])
  }, [
    _createVNode(_Transition, { name: "sidebarLogoFade" }, {
      default: _withCtx(() => [
        (!_ctx.collapse)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: "collapse",
              class: "sidebar-logo-link",
              to: "/"
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: _ctx.concise,
                  class: "sidebar-logo"
                }, null, 8, _hoisted_1)
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_router_link, {
              key: "expand",
              class: "sidebar-logo-link",
              to: "/"
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  class: "sidebar-logo",
                  src: _ctx.Logo
                }, null, 8, _hoisted_2)
              ]),
              _: 1
            }))
      ]),
      _: 1
    })
  ], 2))
}