
import { computed, defineComponent } from 'vue'
export default defineComponent({
  name: 'FargoPagination',
  props: {
    total: { required: true, type: Number },
    page: { type: Number, default: 1 },
    limit: { type: Number, default: 10 },
    pageSizes: { type: Array, default: () => [10, 20, 30, 50, 100, 200] },
    layout: { type: String, default: 'sizes, prev, pager, next, jumper' },
    background: { type: Boolean, default: true },
    hidden: { type: Boolean, default: false },
    beforeText: { type: String, default: '共' },
    afterTexy: { type: String, default: '条' }
  },
  emits: ['update:page', 'update:limit', 'pagination'],
  setup (props, { emit }) {
    const currentPage = computed({
      get () {
        return props.page
      },
      set (val) {
        emit('update:page', val)
      }
    })
    const pageSize = computed({
      get () {
        return props.limit
      },
      set (val) {
        emit('update:limit', val)
      }
    })
    const handleSizeChange = (val: number) => {
      emit('update:page', 1)
      emit('pagination', { page: 1, limit: val })
    }
    const handleCurrentChange = (val: number) => {
      emit('pagination', { page: val, limit: pageSize })
    }
    return {
      currentPage,
      pageSize,
      handleSizeChange,
      handleCurrentChange
    }
  }
})
