
import { defineComponent, reactive, watch, toRefs, computed } from 'vue'
import unCheckedIcon from '@/assets/images/icons/unchecked.svg'
import checkedIcon from '@/assets/images/icons/checked.svg'
import disabledIcon from '@/assets/images/icons/disabled.svg'
import allCheckedIcon from '@/assets/images/icons/all_checked.svg'
import { mktClass } from '@/utils/color'
import dayjs from 'dayjs'
import { STATES, ENTRUSTPROPMAP } from '@/constant/index'
import { EntrustBs } from '@/constant/enum'

export default defineComponent({
  name: 'OrderTable',
  props: {
    data: { type: Array, default: () => [] } as any,
    tHeaderList: { type: Array, default: () => [] } as any,
    limit: { type: Number, default: 10 },
    loading: { type: Boolean, default: false },
    isUpdate: { type: Boolean, default: false }, // 是否改单
    toggleIndex: { type: Number, default: 0 }
  },
  emits: ['changeSelect', 'update:data', 'checkAll', 'rowClick'],
  setup(props, { emit }) {
    const state = reactive({
      checkAll: false
    })
    // 监听全选
    watch(
      () => props.data,
      newValue => {
        if (newValue.length) {
          const map = new Map()
          const arr1 = newValue?.filter((v: any) => v?.selectStatus)
          if (arr1.length) {
            arr1.forEach((item: any, index: number) => {
              item.index = index + 1
              item.tabIndexId = index + 'tabIndexId'
              map.set(item.tabIndexId, item)
            })
            props.data.forEach((v: any) => {
              if (v?.tabIndexId === map.get(v?.tabIndexId)?.tabIndexId) {
                v.indexSel = map.get(v?.tabIndexId)?.index
              }
            })
          }
          if (arr1.length === newValue.length) {
            state.checkAll = true
          } else {
            state.checkAll = false
          }
        }
      },
      { deep: true }
    )
    // 改变选中状态
    const changeCheckbox = (data: any, val: any) => {
      data.selectStatus = val
      emit('changeSelect', data, val)
      emit('update:data', props.data)
    }
    // 改变全选状态
    const handleCheckAll = (val: boolean) => {
      console.log(val, '全选')
      // eslint-disable-next-line
      props.data?.forEach((v: any) => {
        v.selectStatus = val
      })
      emit('update:data', props.data)
      emit('checkAll', val)
    }
    // 表头全选禁用
    const isDisabled = computed(() =>
      props.data?.some((item: any) => item.disabled)
    )
    const rowClick = (row: any) => {
      emit('rowClick', row)
    }
    const formatData = (val: any, number: number) => {
      return val.toLocaleString('en-US', {
        minimumFractionDigits: number,
        maximumFractionDigits: number
      })
    }
    return {
      ...toRefs(state),
      isDisabled,
      rowClick,
      unCheckedIcon,
      checkedIcon,
      disabledIcon,
      allCheckedIcon,
      handleCheckAll,
      changeCheckbox,
      mktClass,
      formatData,
      ENTRUSTPROPMAP,
      STATES,
      EntrustBs,
      dayjs
    }
  }
})
