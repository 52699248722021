
import { defineComponent } from 'vue'
import storage, { StorageType } from '@/utils/storage'
export default defineComponent({
  name: 'PromptDialog',
  props: {
    dialogVisible: { type: Boolean, default: false }
  },
  emits: ['update:dialogVisible', 'confirm'],
  setup (props, { emit }) {
    const confirm = () => {
      emit('update:dialogVisible', false)
      emit('confirm')
    }
    const close = () => {
      emit('update:dialogVisible', false)
      storage.rcRemoveItem(StorageType.session, 'reviseFlag')
    }
    return {
      confirm,
      close
    }
  }
})
