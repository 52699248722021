
// import { createStore, createLogger } from 'vuex'
import { createStore } from 'vuex'
// import createPersistedState from 'vuex-persistedstate'
import { store as app, AppStore, AppState } from '@/store/modules/app'
import { store as settings, SettingStore, SettingsState } from '@/store/modules/settings'
import { store as permission, PermissionStore, PermissionState } from '@/store/modules/permission'
import { store as user, UserStore, UserState } from '@/store/modules/user'
import { store as tagViews, TagsStore, TagsViewState } from '@/store/modules/tagsview'
import { CommonState } from '@/store/modules/common/state'
import quot from '@/store/modules/quot'
import { common } from './modules/common'
import createPersistedstate from 'vuex-persistedstate'

export interface RootState {
    app: AppState
    settings: SettingsState
    permission: PermissionState
    user: UserState
    tagViews: TagsViewState
    common: CommonState
}

export type Store = AppStore<Pick<RootState, 'app'>> & SettingStore<Pick<RootState, 'settings'>>
& PermissionStore<Pick<RootState, 'permission'>> & UserStore<Pick<RootState, 'user'>>
& TagsStore<Pick<RootState, 'tagViews'>>

// 在开发环境中插入日志记录器
// const debug = process.env.NODE_ENV !== 'production'
// const plugins = debug ? [createLogger({})] : []
// Plug in session storage based persistence
// plugins.push(createPersistedState({ storage: window.sessionStorage }))

export const store = createStore({
  // plugins,
  modules: {
    app,
    settings,
    permission,
    user,
    tagViews,
    quot,
    common
  },
  plugins: [
    createPersistedstate({
      key: 'common',
      paths: ['common']
    })
  ]
})

export function useStore () {
  return store
}
