
import { computed, defineComponent } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'TimeoutDialog',
  props: {
    timeoutDialogStatus: { type: Boolean, default: false }
  },
  emits: ['update:timeoutDialogStatus'],
  setup(props, { emit }) {
    const router = useRouter()
    const dialogVisibleFlag = computed({
      get() {
        return props.timeoutDialogStatus
      },
      set(val: boolean) {
        emit('update:timeoutDialogStatus', val)
      }
    })
    const confirm = () => {
      router.push({ path: '/lpoa/realTimeData' })
      emit('update:timeoutDialogStatus', false)
    }
    const close = () => {
      // props.timeoutDialog = false
      emit('update:timeoutDialogStatus', false)
    }
    return {
      dialogVisibleFlag,
      confirm,
      close
    }
  }
})
