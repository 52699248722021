
import { computed, reactive, toRefs } from 'vue'
import { useStore } from '@/store'
import { AppActionTypes } from '@/store/modules/app/action-types'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import webSocket from '@/mqtt'
import storage, { StorageType } from '@/utils/storage'
import { useMutation } from '@vue/apollo-composable'
import * as gql from '@/graphql'
import { ElMessage } from 'element-plus'
export default {
  props: {
    pwdDialog: {
      type: Boolean,
      default: false
    }
  },
  emits: ['revisePwd'],
  setup(props: any, { emit }: any) {
    const store = useStore()
    const router = useRouter()
    const { t } = useI18n()
    const sidebar = computed(() => {
      return store.state.app.sidebar
    })
    const device = computed(() => {
      return store.state.app.device.toString()
    })
    const avatar = computed(() => {
      return store.state.user.avatar
    })
    const version = computed(() => process.env.VUE_APP_VERSION || 'v0.0.0')
    // 退出登录
    const { mutate: registerDeviceFunc } = useMutation(gql.lpoaLogoutManager)
    const state = reactive({
      pwdDialog: false,
      toggleSideBar: () => {
        store.dispatch(AppActionTypes.ACTION_TOGGLE_SIDEBAR, false)
      },
      logout: () => {
        // useStore().dispatch(UserActionTypes.ACTION_LOGIN_OUT)
        // 清除sessionId
        registerDeviceFunc().then(res => {
          console.log(res)
          if (res?.data.lpoaLogoutManager.ok) {
            webSocket.close()
            router.push({
              name: 'Login'
            })
            // window.localStorage.clear()
            window.sessionStorage.clear()
            storage.rcRemoveItem(StorageType.local, 'sessionId')
            storage.rcRemoveItem(StorageType.local, 'userInfo')
            storage.rcRemoveItem(StorageType.local, 'userData')
            storage.rcRemoveItem(StorageType.session, 'reminder')
            ElMessage({
              message: '退出登录成功',
              type: 'success',
              customClass: 'custom-success'
            })
          }
        })
      },
      userIcon: require('@/assets/images/home/user.png'),
      manager: JSON.parse(storage.rcGetItem(StorageType.local, 'userInfo'))
    })
    const revisePwd = () => {
      emit('revisePwd', true)
    }
    return {
      revisePwd,
      sidebar,
      device,
      avatar,
      ...toRefs(state),
      t,
      version
    }
  }
}
