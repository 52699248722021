import { RootState } from '@/store'
import { ActionContext, ActionTree } from 'vuex'
import { CommonActionTypes } from './action-types'
import { CommonMutationTypes } from './mutation-types'
import { Mutations } from './mutations'
import { CommonState, RateType } from './state'

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
} & Omit<ActionContext<CommonState, RootState>, 'commit'>

export interface Actions {
  [CommonActionTypes.SET_MONEY_TYPE](
    { commit }: AugmentedActionContext,
    moneyType: string
  ): void
  [CommonActionTypes.SET_EXCHANGE_RATE](
    { commit }: AugmentedActionContext,
    exchangeRate: RateType[]
  ): void
}

export const actions: ActionTree<CommonState, RootState> & Actions = {
  [CommonActionTypes.SET_MONEY_TYPE] ({ commit }, moneyType: string): void {
    commit(CommonMutationTypes.SET_MONEY_TYPE, moneyType)
  },
  [CommonActionTypes.SET_EXCHANGE_RATE] (
    { commit },
    exchangeRate: RateType[]
  ): void {
    commit(CommonMutationTypes.SET_EXCHANGE_RATE, exchangeRate)
  }
}
