import * as types from './mutations-type'
import _ from 'lodash'

type TopicInter = {
  type?: number
  data: string[]
}

export enum SubType {
  sub,
  unSub,
  close
}

const state = {
  quot: {},
  orderList: '111',
  topic: [],
  currencyMessage: '',
  noticeList: []
}
const getters = {
  quot: (state: any) => state.quot,
  topic: (state: any) => state.topic,
  currencyMessage: (state: any) => state.currencyMessage,
  noticeList: (state: any) => state.noticeList
}
const mutations = {
  [types.QUOT_DETAIL] (state: any, payload: any) {
    state.quot = { ...state.quot, detail: payload.detail }
  },
  [types.ASKBID5] (state: any, payload: any) {
    state.quot = { ...state.quot, askBid: payload.askBid }
  },
  [types.GET_ORDERS] (state: any, payload: any) {
    console.log(payload, 'payloadpayload')
    state.orderList = payload
  },
  [types.CURRENCY_LIST] (state: any, payload: any) {
    state.currencyMessage = payload
  },
  [types.TOPIC] (state: any, payload: TopicInter) {
    switch (payload.type) {
      case SubType.sub:
        state.topic = _.uniqWith([...state.topic, ...payload.data])
        break
      case SubType.unSub:
        state.topic = _.difference(state.topic, payload.data)
        break
      case SubType.close:
        state.topic = []
        break
      default:
        break
    }
  },
  [types.GET_NOTICE_LIST] (state: any, payload: any) {
    state.noticeList = _.uniqBy(
      [...payload, ...state.noticeList],
      'id'
    )?.filter((item: any) => item.status === 2 || !item.status)
    console.log(state.noticeList, 'state.noticeList')
  }
}

export default {
  state,
  getters,
  mutations
}
