import { actions } from './actions'
import { mutations } from './mutations'
import { CommonState, state } from './state'

export const common = {
  state,
  actions,
  mutations,
  getters: {
    getCommonInfo (state: CommonState): CommonState {
      return state
    }
  }
}
