
import { defineComponent } from 'vue'
import settings from '@/config/default/setting.config'
import Logo from '@/assets/images/home/logo.svg'
import concise from '@/assets/images/home/concise-logo.svg'
export default defineComponent({
  props: {
    collapse: {
      type: Boolean,
      default: true
    }
  },
  setup () {
    const title = settings.title
    return {
      title,
      Logo,
      concise
    }
  }
})
