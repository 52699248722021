import { EntrustStatus } from './enum'

// export const STATES = {
//   0: '未报',
//   1: '待报',
//   2: '已报',
//   3: '已报待撤',
//   4: '部成待撤',
//   5: '部撤',
//   6: '已撤',
//   7: '部成',
//   8: '已成',
//   9: '废单',
//   A: '已报待改',
//   B: '待复核[人手买方待确认]',
//   C: '重新检查中',
//   D: '待触发',
//   E: '部成待改',
//   F: '撤单(过期)',
//   G: '部撤(过期)',
//   H: '待复核',
//   J: '复核未通过',
//   N: '修改待复核',
//   O: '修改待复核[部成]',
//   P: '修改待复核[未报]',
//   W: '待确认'
// }
export const STATES = {
  0: '等待提交',
  1: '等待提交',
  2: '已提交',
  3: '已提待撤',
  4: '部成待撤',
  5: '部分撤单',
  6: '已撤单',
  7: '部分成交',
  8: '全部成交',
  9: '已作废',
  A: '已报待改',
  B: '待复核[人手买方待确认]',
  C: '重新检查中',
  D: '待触发',
  E: '部成待改',
  F: '撤单(过期)',
  G: '部撤(过期)',
  H: '待复核',
  J: '复核未通过',
  N: '修改待复核',
  O: '修改待复核[部成]',
  P: '修改待复核[未报]',
  W: '待确认',
  '': '下单失败'
}

export const ENTRUSTPROPMAP = {
  ELO: '限价单 (增强) ',
  MO: '市价单',
  ALO: '竞价限价单',
  AMO: '竞价市价单',
  LO: '限价单'
}

export const ORDER_CANCEL = [
  EntrustStatus.ENTRUSTSTATYS_0,
  EntrustStatus.ENTRUSTSTATYS_1,
  EntrustStatus.ENTRUSTSTATYS_2,
  EntrustStatus.ENTRUSTSTATYS_7,
  EntrustStatus.ENTRUSTSTATYS_B,
  EntrustStatus.ENTRUSTSTATYS_D,
  EntrustStatus.ENTRUSTSTATYS_H,
  EntrustStatus.ENTRUSTSTATYS_J,
  EntrustStatus.ENTRUSTSTATYS_N,
  EntrustStatus.ENTRUSTSTATYS_O,
  EntrustStatus.ENTRUSTSTATYS_P,
  EntrustStatus.ENTRUSTSTATYS_W
]
// 改单
export const ORDER_UPDATE = [
  EntrustStatus.ENTRUSTSTATYS_0,
  EntrustStatus.ENTRUSTSTATYS_1,
  EntrustStatus.ENTRUSTSTATYS_2,
  EntrustStatus.ENTRUSTSTATYS_7
]

export const LPOASTATE = {
  0: '处理中',
  1: '全部成交',
  2: '废单',
  // 3: '部分已报',
  4: '已撤',
  5: '已完结'
}

export const ENTRUSTBSMAP = {
  bid: '买入',
  ask: '卖出'
}
