/**
 * 股票行情数据字段，同java后台配置文件一致
 *
 * 以下字段额外配置
 * SFIELD
 * 999  //判断是否是深港通、沪港通标的
 * 1000 // AH股溢价计算
 * 1001 // 获取redis最新的美股盘前盘后数据
 *
 */

 export default {
  ASSETID: 0, // 资产ID
  NAME: 1, // 资产名称
  PRICE: 2, // 现价
  HIGH: 3, // 最高
  LOW: 4, // 最低
  OPEN: 5, // 开盘价
  PRECLOSE: 6, // 昨收价
  TURNOVER: 7, // 成交额
  VOLUME: 8, // 成交量
  CHANGE: 9, // 涨跌
  CHANGEPCT: 10, // 涨跌幅
  COMMITTEE: 11, // 委比
  VOLRATE: 12, // 量比
  LIMITUP: 13, // 涨停价
  LIMITDOWN: 14, // 跌停价
  TS: 15, // 时间戳
  ASK5: 16, // 卖价5
  ASK4: 17, // 卖价4
  ASK3: 18, // 卖价5
  ASK2: 19, // 卖价2
  ASK1: 20, // 卖价1
  ASKQTY5: 21, // 卖量5
  ASKQTY4: 22, // 卖量4
  ASKQTY3: 23, // 卖量3
  ASKQTY2: 24, // 卖量2
  ASKQTY1: 25, // 卖量1
  BID1: 26, // 买价1
  BID2: 27, // 买价2
  BID3: 28, // 买价3
  BID4: 29, // 买价4
  BID5: 30, // 买价5
  BIDQTY1: 31, // 买量1
  BIDQTY2: 32, // 买量2
  BIDQTY3: 33, // 买量3
  BIDQTY4: 34, // 买量4
  BIDQTY5: 35, // 买量5
  STYPE: 36, // 证券子类别
  TURNRATE: 37, // 换手率
  TOTALVAL: 38, // 总市值
  PE: 39, // 市盈率
  PB: 40, // 市净率
  FMKTVAL: 41, // 流通市值
  STATUS: 42, // 股票状态
  UPNUMS: 43, // 涨家数
  EVENNUMS: 44, // 平家数
  DOWNNUMS: 45, // 跌家数
  NETVALUE: 46, // 单位净值
  TOTALNETVALUE: 47, // 累计净值
  PREMIUMRATE: 48, // 溢价率
  LISTINGDATE: 49, // 成立日期

  BID6: 50, // 买价6
  BID7: 51, // 买价7
  BID8: 52, // 买价8
  BID9: 53, // 买价9
  BID10: 54, // 买价10
  BIDQTY6: 55, // 买量6
  BIDQTY7: 56, // 买量7
  BIDQTY8: 57, // 买量8
  BIDQTY9: 58, // 买量9
  BIDQTY10: 59, // 买量10
  ASK10: 60, // 卖价10
  ASK9: 61, // 卖价9
  ASK8: 62, // 卖价8
  ASK7: 63, // 卖价7
  ASK6: 64, // 卖价6
  ASKQTY10: 65, // 卖量10
  ASKQTY9: 66, // 卖量9
  ASKQTY8: 67, // 卖量8
  ASKQTY7: 68, // 卖量7
  ASKQTY6: 69, // 卖量6

  // add by jiangbin
  DIVYLD: 70, // 周息率 dividend yield
  WEEK52HIGH: 71, // 52周最高
  WEEK52LOW: 72, // 52周最低

  // 十档 买入排队数量
  B1ORDERCOUNT: 73,
  B2ORDERCOUNT: 74,
  B3ORDERCOUNT: 75,
  B4ORDERCOUNT: 76,
  B5ORDERCOUNT: 77,
  B6ORDERCOUNT: 78,
  B7ORDERCOUNT: 79,
  B8ORDERCOUNT: 80,
  B9ORDERCOUNT: 81,
  B10ORDERCOUNT: 82,

  // 十档 卖出排队数量
  S1ORDERCOUNT: 83,
  S2ORDERCOUNT: 84,
  S3ORDERCOUNT: 85,
  S4ORDERCOUNT: 86,
  S5ORDERCOUNT: 87,
  S6ORDERCOUNT: 88,
  S7ORDERCOUNT: 89,
  S8ORDERCOUNT: 90,
  S9ORDERCOUNT: 91,
  S10ORDERCOUNT: 92,

  // 为模拟炒股增加的返回字段
  SPELLINGABBR: 100, // 拼音简称 spelling_abbr
  TOTAL: 101, // 总股本 total
  FLSHR: 102, // 流通股份fl_shr
  TRNP: 103, // 净利润tr_np
  BPS: 104, // 每股净资产 bps
  LOTSIZE: 105, // 单位交易量即每手股数lot_size

  TIME: 106, // 时间
  TIMENO: 107, // 时间节点序号
  AMPLITUDE: 108, // 振幅

  COMMISSIONBUYVOL: 109, // 委买数量
  COMMISSIONSELLVOL: 110, // 委卖数量
  BROKERQUEUE: 111, // 经纪席位
  MONEYTYPE: 112, // 币种

  TIMEZONE: 113, // 美国时区

  STRIKELLEVEL: 124, // 行使价
  ENTITLEMENTRATIO: 125, // 换股比率
  ISSUESIZE: 126, // 发行量
  OSRATIO: 127, // 街货比
  CALLLEVEL: 128, // 回收价
  DELTA: 129, // 对冲值
  IV: 130, // 引申波幅
  MATURITY: 131, // 到期日

  OPENTSTIME: 132, // 开盘时间
  CLOSETSTIME: 133, // 收盘时间

  HISHIGH: 134, // 历史最高
  HISLOW: 135, // 历史最低
  AVGPRICE: 136, // 平均价

  LEVERAGE: 137, // 有效杠杆
  LEVERAGERATIO: 138, // 杠杆比例

  EPSP: 139, // 收益
  RISK: 140, // 查询是否高风险股票

  ISSELETSELF: 141, // 是否自选
  LASTTRADINGDATE: 142, // 最后交易日
  DOGFALLSPOT: 143, // 打和点
  IS_ZHT: 157, // 是否为中华通
  SCH_TYPE: 153, // 价差类型
  FIELD_US_BEFORE: 162, // 美股盘前行情数据
  FIELD_US_AFTER: 163, // 美股盘后行情数据
  AF_BF_STATUS: 165 // 盘前盘后状态
}
