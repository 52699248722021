
import { useStore } from '@/store'
import { defineComponent, reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
export default defineComponent({
  setup () {
    const store = useStore()
    const route = useRoute()
    const state = reactive({
      cachedViews: store.state.tagViews.cachedViews
    })

    const key = () => {
      return route.path
    }
    return {
      ...toRefs(state),
      key
    }
  }
})
