export type DesensitizationOptions = {
  format?: number | string
  type?: number
  position?: number
}

export enum DesensitizationFormat {
  telPhone,
  cnName,
  idCard
}

export enum DesensitizationType {
  full,
  part
}

export enum DesensitizationPosition {
  center,
  start,
  end
}

export default function Desensitization (
  content: string,
  options: DesensitizationOptions
) {
  const {
    format,
    type = DesensitizationType.part,
    position = DesensitizationPosition.center
  } = options as DesensitizationOptions
  const desensitizeIdentifier = '*'
  let desensitizePercent = 1 / 3
  const result: string[] = []
  if (type === DesensitizationType.full) {
    for (let i = 0; i < content.length; i++) {
      result.push(desensitizeIdentifier)
    }
    return result.join('')
  }
  // 针对特殊业务场景调整打*的比例
  switch (format) {
    case DesensitizationFormat.telPhone:
      desensitizePercent = 5 / 11
      break
    case DesensitizationFormat.cnName:
      desensitizePercent = 1 / 3
      break
    case DesensitizationFormat.idCard:
      desensitizePercent = 4 / 9
      break
    default:
      break
  }

  // 计算打*的起始位置
  const contentLength = content.length
  const desensitizationLength = Math.ceil(contentLength * desensitizePercent)

  const range = {
    start: 0,
    end: 0
  }
  switch (position) {
    case DesensitizationPosition.center:
      range.start =
        Math.floor(contentLength / 2) - Math.floor(desensitizationLength / 2)
      range.end =
        Math.floor(contentLength / 2) -
        Math.floor(desensitizationLength / 2) +
        (desensitizationLength - 1)
      break
    case DesensitizationPosition.start:
      range.end = desensitizationLength - 1
      break
    case DesensitizationPosition.end:
      range.start = contentLength - desensitizationLength
      range.end = contentLength - 1
      break
    default:
      break
  }

  for (let i = 0; i < content.length; i++) {
    if (i >= range.start && i <= range.end) {
      result.push(desensitizeIdentifier)
      continue
    }
    result.push(content[i])
  }
  return result.join('')
}
