import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ hidden: _ctx.hidden, 'pagination-container': true })
  }, [
    _withDirectives(_createElementVNode("span", { class: "text" }, _toDisplayString(_ctx.beforeText + ' ' + _ctx.total + ' ' + _ctx.afterTexy), 513), [
      [_vShow, _ctx.total]
    ]),
    _createVNode(_component_el_pagination, _mergeProps({
      background: _ctx.background,
      "current-page": _ctx.currentPage,
      "onUpdate:currentPage": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentPage) = $event)),
      "page-size": _ctx.pageSize,
      "onUpdate:pageSize": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pageSize) = $event)),
      layout: _ctx.layout,
      "page-sizes": _ctx.pageSizes,
      total: _ctx.total
    }, _ctx.$attrs, {
      onSizeChange: _ctx.handleSizeChange,
      onCurrentChange: _ctx.handleCurrentChange
    }), null, 16, ["background", "current-page", "page-size", "layout", "page-sizes", "total", "onSizeChange", "onCurrentChange"])
  ], 2))
}