export interface RateType {
  rate: string
  ccy: string
  baseCcy: string
}
export interface CommonState {
  moneyType: string
  exchangeRate: RateType[]
  // userInfo: {
  //   username: string
  // }
}

export const state: CommonState = {
  moneyType: 'HKD',
  exchangeRate: [
    {
      rate: '1.1415',
      ccy: 'CNY',
      baseCcy: 'N'
    },
    { rate: '1.0', ccy: 'HKD', baseCcy: 'Y' },
    { rate: '7.7985', ccy: 'USD', baseCcy: 'N' }
  ]
  // userInfo: {
  //   username: 'username'
  // }
}
