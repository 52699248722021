import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
  from
} from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import signWrap from '@/apollo-client/sign'
import { ElMessage } from 'element-plus'
import router from '@/router'
import storage, { StorageType } from '@/utils/storage'
import { NetworkError } from '@/constant/enum'
import webSocket from '@/mqtt'
const cache = new InMemoryCache()

const BASE_URL = process.env.VUE_APP_BASE_API
const DMZ_URL = process.env.VUE_APP_DMZ_API
const QUOTE_URL = process.env.VUE_APP_QUOTE_API

// 基准请求
const httpLink = new HttpLink({
  uri: BASE_URL
})

// dmz请求
const dmzLink = new HttpLink({
  uri: DMZ_URL
})

// 行情请求
const quoteLink = new HttpLink({
  uri: QUOTE_URL
})

const errorLink = onError(({ graphQLErrors, networkError }: any) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((item: any) => {
      console.log(item, 'error')
      switch (item.err) {
        case NetworkError.CODE_542:
        case NetworkError.CODE_543:
        case NetworkError.CODE_544:
        case NetworkError.CODE_546:
        case 547:
          ElMessage.error('请求异常，请稍后重试！')
          break
        case 545:
          webSocket.close().then(() => {
            console.log('webSocket.close()')
            ElMessage.error('长时间未操作，请重新登录！')
            storage.rcRemoveItem(StorageType.local, 'sessionId')
            storage.rcRemoveItem(StorageType.session, 'unlock')
            storage.rcRemoveItem(StorageType.session, 'reminder')
            router.push('/login')
          })
          break
        default:
          if (item.message) {
            ElMessage.error(item.message)
          }
          break
      }
    })
  }

  // 网络错误处理
  if (networkError) {
    if (String(networkError).includes('Failed to fetch')) {
      ElMessage.error('网络异常，请刷新页面重试！')
    }
    console.log(`[Network error]: ${networkError}`)
  }
})

const additiveLink = new ApolloLink((operation, forward) => {
  operation.setContext(() => ({
    headers: {
      'content-type': 'application/json',
      ...signWrap(operation)
    }
  }))

  return forward(operation).map(data => {
    console.log('data', data)
    return data
  })
})
export const baseClient = new ApolloClient({
  cache,
  link: from([additiveLink, errorLink, httpLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache'
    },
    query: {
      fetchPolicy: 'no-cache'
    },
    mutate: {
      fetchPolicy: 'no-cache'
    }
  }
})

export const dmzClient = new ApolloClient({
  cache,
  link: from([additiveLink, errorLink, dmzLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache'
    },
    query: {
      fetchPolicy: 'no-cache'
    },
    mutate: {
      fetchPolicy: 'no-cache'
    }
  }
})

export const quoteClient = new ApolloClient({
  cache,
  link: from([additiveLink, errorLink, quoteLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache'
    },
    query: {
      fetchPolicy: 'no-cache'
    },
    mutate: {
      fetchPolicy: 'no-cache'
    }
  }
})
