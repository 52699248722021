import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c8fdbbce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_Transition, {
      name: "fade-transform",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_KeepAlive, { include: _ctx.cachedViews }, [
          (_openBlock(), _createBlock(_component_router_view, { key: _ctx.key }))
        ], 1032, ["include"]))
      ]),
      _: 1
    })
  ]))
}