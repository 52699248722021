
// function capitalizeFirstLetter(string: string) {
//   return string.charAt(0).toUpperCase() + string.slice(1)
// }

// 获取components文件夹下以.vue命名的文件
// 语法如下：(directory, useSubdirectories = false, regExp = /^\.\//)
const requireComponent = require.context('./', true, /\.vue$/)

export default {
  install (app: { component: (arg0: string, arg1: string) => void }): void {
    requireComponent.keys().forEach((path) => {
      const component = requireComponent(path).default
      app.component(component.name, component)
    })
  }
}
