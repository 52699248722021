import dayjs from 'dayjs'
import stkFieldNo from '@/define/stkFieldNo'
import { isDefined } from '@/utils/is'
import { COMMON } from '@/define'

/**
 * 转换股票行情数据，根据传递的行情字段转换
 * @param {Array} data 股票数据
 * @param {Object} obj 对应转换字段
 */
export function transformQuoteField (data: any, fields = '') {
  // const { fields }: any = obj
  const arr: any = []
  data.forEach((item: any) => {
    const obj: any = {}
    fields.split('|').forEach((funcId: string, ind: number) => {
      const origin = item[ind]
      const temp = isDefined(origin) ? origin : COMMON.EMPTY_LABEL
      switch (Number(funcId)) {
        case stkFieldNo.ASSETID:
          obj.assetId = temp
          break
        case stkFieldNo.NAME:
          obj.stkName = temp
          break
        case stkFieldNo.PRICE:
          obj.price = temp
          break
        case stkFieldNo.HIGH:
          obj.high = temp
          break
        case stkFieldNo.LOW:
          obj.low = temp
          break
        case stkFieldNo.OPEN:
          obj.open = temp
          break
        case stkFieldNo.PRECLOSE:
          obj.preClose = temp
          break
        case stkFieldNo.TURNOVER:
          obj.turnOver = temp
          break
        case stkFieldNo.VOLUME:
          obj.volume = temp
          break
        case stkFieldNo.CHANGE:
          obj.change = temp
          break
        case stkFieldNo.CHANGEPCT:
          obj.changePct = temp
          break
        case stkFieldNo.COMMITTEE:
          obj.committee = temp
          break
        case stkFieldNo.VOLRATE:
          obj.volRate = temp
          break
        case stkFieldNo.LIMITUP:
          obj.limitUp = temp
          break
        case stkFieldNo.LIMITDOWN:
          obj.limitDown = temp
          break
        case stkFieldNo.TS:
          obj.ts = temp
          break
        case stkFieldNo.ASK10:
          obj.ask10 = temp
          break
        case stkFieldNo.ASK9:
          obj.ask9 = temp
          break
        case stkFieldNo.ASK8:
          obj.ask8 = temp
          break
        case stkFieldNo.ASK7:
          obj.ask7 = temp
          break
        case stkFieldNo.ASK6:
          obj.ask6 = temp
          break
        case stkFieldNo.ASK5:
          obj.ask5 = temp
          break
        case stkFieldNo.ASK4:
          obj.ask4 = temp
          break
        case stkFieldNo.ASK3:
          obj.ask3 = temp
          break
        case stkFieldNo.ASK2:
          obj.ask2 = temp
          break
        case stkFieldNo.ASK1:
          obj.ask1 = temp
          break
        case stkFieldNo.ASKQTY10:
          obj.askQty10 = temp
          break
        case stkFieldNo.ASKQTY9:
          obj.askQty9 = temp
          break
        case stkFieldNo.ASKQTY8:
          obj.askQty8 = temp
          break
        case stkFieldNo.ASKQTY7:
          obj.askQty7 = temp
          break
        case stkFieldNo.ASKQTY6:
          obj.askQty6 = temp
          break
        case stkFieldNo.ASKQTY5:
          obj.askQty5 = temp
          break
        case stkFieldNo.ASKQTY4:
          obj.askQty4 = temp
          break
        case stkFieldNo.ASKQTY3:
          obj.askQty3 = temp
          break
        case stkFieldNo.ASKQTY2:
          obj.askQty2 = temp
          break
        case stkFieldNo.ASKQTY1:
          obj.askQty1 = temp
          break
        case stkFieldNo.BID1:
          obj.bid1 = temp
          break
        case stkFieldNo.BID2:
          obj.bid2 = temp
          break
        case stkFieldNo.BID3:
          obj.bid3 = temp
          break
        case stkFieldNo.BID4:
          obj.bid4 = temp
          break
        case stkFieldNo.BID5:
          obj.bid5 = temp
          break
        case stkFieldNo.BID6:
          obj.bid6 = temp
          break
        case stkFieldNo.BID7:
          obj.bid7 = temp
          break
        case stkFieldNo.BID8:
          obj.bid8 = temp
          break
        case stkFieldNo.BID9:
          obj.bid9 = temp
          break
        case stkFieldNo.BID10:
          obj.bid10 = temp
          break
        case stkFieldNo.BIDQTY1:
          obj.bidQty1 = temp
          break
        case stkFieldNo.BIDQTY2:
          obj.bidQty2 = temp
          break
        case stkFieldNo.BIDQTY3:
          obj.bidQty3 = temp
          break
        case stkFieldNo.BIDQTY4:
          obj.bidQty4 = temp
          break
        case stkFieldNo.BIDQTY5:
          obj.bidQty5 = temp
          break
        case stkFieldNo.BIDQTY6:
          obj.bidQty6 = temp
          break
        case stkFieldNo.BIDQTY7:
          obj.bidQty7 = temp
          break
        case stkFieldNo.BIDQTY8:
          obj.bidQty8 = temp
          break
        case stkFieldNo.BIDQTY9:
          obj.bidQty9 = temp
          break
        case stkFieldNo.BIDQTY10:
          obj.bidQty10 = temp
          break
        case stkFieldNo.STYPE:
          obj.sType = Number(temp)
          break
        case stkFieldNo.TURNRATE:
          obj.turnRate = temp
          break
        case stkFieldNo.TOTALVAL:
          obj.totalVal = temp
          break
        case stkFieldNo.PE:
          obj.pe = temp
          break
        case stkFieldNo.PB:
          obj.pb = temp
          break
        case stkFieldNo.FMKTVAL:
          obj.fmktVal = temp
          break
        case stkFieldNo.STATUS:
          obj.status = temp
          break
        case stkFieldNo.UPNUMS:
          obj.upNums = temp
          break
        case stkFieldNo.EVENNUMS:
          obj.evenNums = temp
          break
        case stkFieldNo.DOWNNUMS:
          obj.downNums = temp
          break
        case stkFieldNo.NETVALUE:
          obj.netValue = temp
          break
        case stkFieldNo.TOTALNETVALUE:
          obj.totalNetValue = temp
          break
        case stkFieldNo.PREMIUMRATE:
          obj.premiumRate = temp
          break
        case stkFieldNo.LISTINGDATE:
          obj.listingDate = temp
          break
        case stkFieldNo.DIVYLD:
          obj.divYld = temp
          break
        case stkFieldNo.WEEK52HIGH:
          obj.week52High = temp
          break
        case stkFieldNo.WEEK52LOW:
          obj.week52Low = temp
          break
        case stkFieldNo.B1ORDERCOUNT:
          obj.b1orderCount = temp
          break
        case stkFieldNo.B2ORDERCOUNT:
          obj.b2orderCount = temp
          break
        case stkFieldNo.B3ORDERCOUNT:
          obj.b3orderCount = temp
          break
        case stkFieldNo.B4ORDERCOUNT:
          obj.b4orderCount = temp
          break
        case stkFieldNo.B5ORDERCOUNT:
          obj.b5orderCount = temp
          break
        case stkFieldNo.B6ORDERCOUNT:
          obj.b6orderCount = temp
          break
        case stkFieldNo.B7ORDERCOUNT:
          obj.b7orderCount = temp
          break
        case stkFieldNo.B8ORDERCOUNT:
          obj.b8orderCount = temp
          break
        case stkFieldNo.B9ORDERCOUNT:
          obj.b9orderCount = temp
          break
        case stkFieldNo.B10ORDERCOUNT:
          obj.b10orderCount = temp
          break
        case stkFieldNo.S1ORDERCOUNT:
          obj.s1orderCount = temp
          break
        case stkFieldNo.S2ORDERCOUNT:
          obj.s2orderCount = temp
          break
        case stkFieldNo.S3ORDERCOUNT:
          obj.s3orderCount = temp
          break
        case stkFieldNo.S4ORDERCOUNT:
          obj.s4orderCount = temp
          break
        case stkFieldNo.S5ORDERCOUNT:
          obj.s5orderCount = temp
          break
        case stkFieldNo.S6ORDERCOUNT:
          obj.s6orderCount = temp
          break
        case stkFieldNo.S7ORDERCOUNT:
          obj.s7orderCount = temp
          break
        case stkFieldNo.S8ORDERCOUNT:
          obj.s8orderCount = temp
          break
        case stkFieldNo.S9ORDERCOUNT:
          obj.s9orderCount = temp
          break
        case stkFieldNo.S10ORDERCOUNT:
          obj.s10orderCount = temp
          break
        case stkFieldNo.SPELLINGABBR:
          obj.spellingAbbr = temp
          break
        case stkFieldNo.TOTAL:
          obj.total = temp
          break
        case stkFieldNo.FLSHR:
          obj.flShr = temp
          break
        case stkFieldNo.TRNP:
          obj.trNp = temp
          break
        case stkFieldNo.BPS:
          obj.bps = temp
          break
        case stkFieldNo.LOTSIZE:
          obj.lotSize = temp
          break
        case stkFieldNo.TIME:
          obj.time = temp
          break
        case stkFieldNo.TIMENO:
          obj.timeNo = temp
          break
        case stkFieldNo.AMPLITUDE:
          obj.amplitude = temp
          break
        case stkFieldNo.COMMISSIONBUYVOL:
          obj.commissionBuyVol = temp
          break
        case stkFieldNo.COMMISSIONSELLVOL:
          obj.commissionSellVol = temp
          break
        case stkFieldNo.BROKERQUEUE:
          obj.brokerQueue = origin
          break
        case stkFieldNo.MONEYTYPE:
          obj.moneyType = temp
          break
        case stkFieldNo.TIMEZONE:
          obj.timeZone = temp
          break
        case stkFieldNo.STRIKELLEVEL:
          obj.strikelLevel = temp
          break
        case stkFieldNo.ENTITLEMENTRATIO:
          obj.entitleMentRatio = temp
          break
        case stkFieldNo.ISSUESIZE:
          obj.issueSize = temp
          break
        case stkFieldNo.OSRATIO:
          obj.osRatio = temp
          break
        case stkFieldNo.CALLLEVEL:
          obj.callLevel = temp
          break
        case stkFieldNo.DELTA:
          obj.delta = temp
          break
        case stkFieldNo.IV:
          obj.iv = temp
          break
        case stkFieldNo.MATURITY:
          // obj.maturity = timestampToTime(temp, 'YYYY/MM/DD')
          obj.maturity = dayjs(temp).format('YYYY/MM/DD')
          break
        case stkFieldNo.OPENTSTIME:
          obj.openTsTime = temp
          break
        case stkFieldNo.CLOSETSTIME:
          obj.closeEtsTime = temp
          break
        case stkFieldNo.HISHIGH:
          obj.hisHigh = temp
          break
        case stkFieldNo.HISLOW:
          obj.hisLow = temp
          break
        case stkFieldNo.AVGPRICE:
          obj.avgPrice = temp
          break
        case stkFieldNo.LEVERAGE:
          obj.leverage = temp
          break
        case stkFieldNo.LEVERAGERATIO:
          obj.leverageRatio = temp
          break
        case stkFieldNo.EPSP:
          obj.epsp = temp
          break
        case stkFieldNo.RISK:
          obj.risk = Boolean(Number(origin))
          break
        case stkFieldNo.ISSELETSELF:
          obj.isSeletSelf = temp
          break
        case stkFieldNo.LASTTRADINGDATE:
          obj.lastTradingDate = temp
          break
        case stkFieldNo.DOGFALLSPOT:
          obj.dogfallSpot = temp
          break
        case stkFieldNo.FIELD_US_BEFORE:
          obj.fieldUsBefore = temp
          break
        case stkFieldNo.FIELD_US_AFTER:
          obj.fieldUsAfter = temp
          break
        case stkFieldNo.AF_BF_STATUS:
          obj.afBfStatus = temp
          break
        case stkFieldNo.IS_ZHT:
          obj.isZht = temp
          break
        case stkFieldNo.SCH_TYPE:
          obj.schType = temp
          break
        case 999:
          obj.isHSGT = origin
          break
        case 1000:
          obj.ah = temp
          break
        case 1001:
          obj.us = temp
          break
        // no default
      }
    })

    arr.push(obj)
  })

  return arr
}

/**
 * 推送实时- 股票 行情盘口数据处理
 * 根据返回数据索引转换
 */
export function transformSocket2 (data = []) {
  return data.map((item: any) => {
    const obj: any = {}
    item.forEach((sub: any, ind: any) => {
      const temp = isDefined(sub) ? sub : COMMON.EMPTY_LABEL
      switch (ind) {
        case 0:
          obj.assetId = temp
          break
        case 1:
          obj.stkName = temp
          break
        case 2:
          obj.assetType = temp
          break
        case 3:
          obj.assetStatus = temp
          break
        case 4:
          obj.preClose = temp
          break
        case 5:
          obj.open = temp
          break
        case 6:
          obj.price = temp
          break
        case 7:
          obj.high = temp
          break
        case 8:
          obj.low = temp
          break
        case 9:
          obj.volume = temp
          break
        case 10:
          obj.turnOver = temp
          break
        case 11:
          obj.change = temp
          break
        case 12:
          obj.changePct = temp
          break
        case 13:
          obj.totalVal = temp
          break
        case 14:
          obj.fmktVal = temp
          break
        case 15:
          obj.fmktVal = temp
          break
        case 16:
          obj.turnRate = temp
          break
        case 17:
          obj.pe = temp
          break
        case 18:
          obj.pe = temp
          break
        case 19:
          obj.pb = temp
          break
        case 20:
          obj.volRate = temp
          break
        case 21:
          obj.committee = temp
          break
        case 22:
          obj.avgPrice = temp
          break
        case 23:
          obj.epsp = temp
          break
        case 24:
          obj.bps = temp
          break
        case 25:
          obj.bps = temp
          break
        // no default
      }
    })
    return obj
  })
}

/**
 * A股买卖5挡。根据返回数据索引转换数据
 * @param {any} data 数据对象
 */
export function transformSocket3 (data: any = []) {
  return data.map((item: any) => {
    const obj: any = {}
    item.forEach((sub: any, ind: any) => {
      const temp = isDefined(sub) ? sub : COMMON.EMPTY_LABEL
      switch (ind) {
        case 0:
          obj.assetId = temp
          break
        case 1:
          obj.price = temp
          break
        case 2:
          obj.ask5 = temp
          break
        case 3:
          obj.ask4 = temp
          break
        case 4:
          obj.ask3 = temp
          break
        case 5:
          obj.ask2 = temp
          break
        case 6:
          obj.ask1 = temp
          break
        case 7:
          obj.askQty5 = temp
          break
        case 8:
          obj.askQty4 = temp
          break
        case 9:
          obj.askQty3 = temp
          break
        case 10:
          obj.askQty2 = temp
          break
        case 11:
          obj.askQty1 = temp
          break
        case 12:
          obj.bid1 = temp
          break
        case 13:
          obj.bid2 = temp
          break
        case 14:
          obj.bid3 = temp
          break
        case 15:
          obj.bid4 = temp
          break
        case 16:
          obj.bid5 = temp
          break
        case 17:
          obj.bidQty1 = temp
          break
        case 18:
          obj.bidQty2 = temp
          break
        case 19:
          obj.bidQty3 = temp
          break
        case 20:
          obj.bidQty4 = temp
          break
        case 21:
          obj.bidQty5 = temp
          break
        case 22:
          // 涨停
          break
        case 23:
          // 跌停
          break
        case 24:
          // 证券类型
          obj.secType = temp
          break
        case 25:
          obj.preClose = temp
          break
        case 26:
          obj.status = temp
          break
        // no default
      }
    })
    return obj
  })
}

/**
 * 美股买卖1挡。根据返回数据索引转换数据
 * @param {any} data 数据对象
 */
export function transformSocket21 (data: any = []) {
  return data.map((item: any) => {
    const obj: any = {}
    item.forEach((sub: any, ind: number) => {
      const temp = isDefined(sub) ? sub : COMMON.EMPTY_LABEL
      switch (ind) {
        case 0:
          obj.assetId = temp
          break
        case 1:
          obj.price = temp
          break
        case 2:
          obj.bid1 = temp
          break
        case 3:
          obj.bidQty1 = temp
          break
        case 4:
          obj.ask1 = temp
          break
        case 5:
          obj.askQty1 = temp
          break
        // no default
      }
    })
    return obj
  })
}

/**
 * 美股盘前盘后格式化
 */
export function transformSocketFieldUsAfterOrBefore (data = '') {
  const obj: any = {}
  data.split('|').forEach((item, idx) => {
    const temp = isDefined(item) ? item : COMMON.EMPTY_LABEL
    switch (idx) {
      case 0:
        obj.afBfStatus = temp
        break
      case 1:
        obj.price = temp
        break
      case 2:
        obj.change = temp
        break
      case 3:
        obj.changePct = temp
        break
      case 4:
        obj.high = temp
        break
      case 5:
        obj.low = temp
        break
      case 6:
        obj.volume = temp
        break
      case 7:
        obj.turnover = temp
        break
      case 8:
        obj.time = temp
        break
      case 9:
        obj.timezone = temp
        break
      case 10:
        obj.date = temp
        break
      case 11:
        obj.bid1 = temp
        break
      case 12:
        obj.bidQty1 = temp
        break
      case 13:
        obj.ask1 = temp
        break
      case 14:
        obj.askQty1 = temp
        break
      // no default
    }
  })
  return obj
}
