import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "notice-dialog" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  class: "dialog-content",
  style: {"white-space":"pre-line"}
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_dialog, {
      title: _ctx.notice.title,
      modelValue: _ctx.visible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
      class: "dialog",
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      "show-close": false
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _ctx.handleClose
          }, {
            default: _withCtx(() => [
              _createTextVNode(" 我知道了 ")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "tips",
          innerHTML: _ctx.notice.tips
        }, null, 8, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.notice.content)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                innerHTML: _ctx.notice.content
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "content")
        ])
      ]),
      _: 3
    }, 8, ["title", "modelValue"])
  ]))
}