
import storage, { StorageType } from '@/utils/storage'
import { computed, defineComponent, reactive, toRefs, watch } from 'vue'
import webSocket from '@/mqtt'
import * as gql from '@/graphql'
import { useQuery } from '@vue/apollo-composable'
import * as quotType from '@/store/modules/quot/mutations-type'
import { store } from '@/store'

export default defineComponent({
  name: 'Notice',
  props: {
    showMarquee: {
      type: Boolean,
      default: true
    }
  },
  emits: ['closeNotice'],
  setup(props, { emit }) {
    const state = reactive({
      dialogVisible: false,
      noticeIndex: 0,
      currentNotice: {},
      notices: [] as any
    })

    const showNotice = computed(() => {
      return (
        !state.notices.length &&
        !storage.rcGetItem(StorageType.session, 'reminder')
      )
    })
    const { onResult } = useQuery(gql.publishedNotices, {
      filter: { clientType: 4 }
    })
    watch(
      () => store.getters.noticeList,
      (list: any) => {
        console.log('noticeList', list)
        state.notices = list
      },
      { deep: true }
    )

    onResult(res => {
      state.notices = res.data.publishedNotices.result
      webSocket.subscribe(['push/public/transMsg'])
      store.commit(quotType.GET_NOTICE_LIST, res.data.publishedNotices.result)
    })
    const changeNotice = (val: number) => {
      state.noticeIndex = val
    }
    const openDialog = () => {
      state.dialogVisible = true
      const index = state.noticeIndex
      state.currentNotice = state.notices[index]
    }

    const dialogClose = () => {
      state.dialogVisible = false
    }

    const closeMarquee = () => {
      emit('closeNotice', false)
      storage.rcSetItem(StorageType.session, 'reminder', true)
    }
    return {
      ...toRefs(state),
      closeMarquee,
      openDialog,
      changeNotice,
      showNotice,
      dialogClose
    }
  }
})
