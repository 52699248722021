
import { defineComponent } from 'vue'
import { numFormat } from '@/utils/num_format'
export default defineComponent({
  name: 'Success',
  props: {
    title: { type: String, default: '改单' },
    dialogVisible: { type: Boolean, default: false },
    tradingData: { type: Array, default: () => [] } as any,
    loading: { type: Boolean, default: false }
  },
  emits: ['update:dialogVisible', 'confirm'],
  setup(props, { emit }) {
    const close = () => {
      emit('update:dialogVisible', false)
    }
    const confirm = () => {
      emit('confirm')
    }
    return {
      close,
      confirm,
      numFormat
    }
  }
})
