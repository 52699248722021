
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'NoticeDialog',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    notice: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['dialogClose', 'dialogConfirm'],
  setup (props, { emit }) {
    const visible = computed(() => props.dialogVisible)
    const handleClose = () => {
      emit('dialogClose')
    }
    const handleConfirm = () => {
      emit('dialogConfirm')
    }
    return {
      handleClose,
      handleConfirm,
      visible
    }
  }
})
