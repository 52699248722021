import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_Notice = _resolveComponent("Notice")!
  const _component_TagsView = _resolveComponent("TagsView")!
  const _component_AppMain = _resolveComponent("AppMain")!
  const _component_Settings = _resolveComponent("Settings")!
  const _component_RightPanel = _resolveComponent("RightPanel")!
  const _component_RevisePwd = _resolveComponent("RevisePwd")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.classObj, "app-wrapper"])
    }, [
      (_ctx.classObj.mobile && _ctx.sidebar.opened)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "drawer-bg",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClickOutside && _ctx.handleClickOutside(...args)))
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_Sidebar, { class: "sidebar-container" }),
      _createElementVNode("div", {
        class: _normalizeClass([{ hasTagsView: _ctx.showTagsView, hasNotice: _ctx.showMarquee }, "main-container"])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass({ 'fixed-header': _ctx.fixedHeader })
        }, [
          _createVNode(_component_Navbar, {
            pwdDialog: _ctx.pwdDialog,
            onRevisePwd: _ctx.revisePwd
          }, null, 8, ["pwdDialog", "onRevisePwd"]),
          _createVNode(_component_Notice, {
            showMarquee: _ctx.showMarquee,
            onCloseNotice: _ctx.closeNotice
          }, null, 8, ["showMarquee", "onCloseNotice"]),
          (_ctx.showTagsView)
            ? (_openBlock(), _createBlock(_component_TagsView, { key: 0 }))
            : _createCommentVNode("", true)
        ], 2),
        _createVNode(_component_AppMain),
        (_ctx.showSettings)
          ? (_openBlock(), _createBlock(_component_RightPanel, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_Settings)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ], 2)
    ], 2),
    _createVNode(_component_RevisePwd, {
      dialogVisible: _ctx.pwdDialog,
      "onUpdate:dialogVisible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pwdDialog) = $event)),
      managerId: _ctx.managerId
    }, null, 8, ["dialogVisible", "managerId"])
  ], 64))
}