import Decimal from 'decimal.js'
/**
 * 加千分号
 * @param num 要转换的数
 * @param n 位数
 * @returns
 */

export function numFormat(num: number, n = 4): string {
  if (!num || num === 0) return '0'
  let filnum = null
  let res = ''
  if (+n !== 0) {
    const numString = String(num).replace(/,/g, '')
    filnum = new Decimal(numString).toFixed(n + 1)
    // filnum = (+num).toFixed(n + 1)
    res = filnum
      .toString()
      .substring(0, filnum.lastIndexOf('.') + n + 1)
      .replace(/\d+/, function (n) {
        // 先提取整数部分
        return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
          return $1 + ','
        })
      })
  } else {
    filnum = num.toString().split('.')[0]
    res = filnum.toString().replace(/\d+/, function (n) {
      // 先提取整数部分
      return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
        return $1 + ','
      })
    })
  }

  // 如果n位-1，代表直接进行千分位处理，小数位不处理
  if (n === -1) {
    res = num.toLocaleString()
  }
  return res
}
/**
 * 小数位点
 */

export function changeDecimal(number: any, bitNum: number): string | number {
  const fx = parseFloat(number)
  if (isNaN(fx)) {
    return 0
  }
  let sx = number.toString()
  let posDecimal = sx.indexOf('.')
  // 没有小数点的加上小数点
  if (posDecimal < 0) {
    posDecimal = sx.length
    if (bitNum !== 0) {
      sx += '.'
    } else {
      // 没有小数点还要保留0位小数
      return sx
    }
  }
  if (sx.length <= posDecimal + bitNum) {
    // eg:122 保留2位小数
    // return 122.00
    while (sx.length <= posDecimal + bitNum) {
      sx += '0'
    }
  } else {
    // eg:1.222222  保留2位小数
    // return 1.22
    sx = sx.substring(0, posDecimal + bitNum + 1)
  }
  return sx
}

/*
 *@Description: 计算求和
 *@param: { newArr: 查询到所有的数据 }
 *@return: {*}
 */
export const arrSum = (newArr = []): any => {
  const filArrMpp = new Map()
  const posArr: any = []
  newArr.forEach((v: any) => {
    if (!filArrMpp.has(v.stockCode)) {
      filArrMpp.set(v.stockCode, [v])
    } else {
      filArrMpp.get(v.stockCode).push(v)
    }
  })

  filArrMpp.forEach((v: any) => {
    if (v.length > 1) {
      // 持仓&可用持仓
      const isFUND = v[0].exchangeType === 'FUND'

      const currentPos = v.reduce((pre: any, cur: any) => {
        return pre + cur.currentAmount
      }, 0)
      v[0].currentPos = !isFUND ? numFormat(currentPos, 0) : currentPos

      v[0].currentPosNum = v.reduce((pre: any, cur: any) => {
        return pre + cur.currentAmount
      }, 0)

      const enablePos = v.reduce((pre: any, cur: any) => {
        return pre + cur.enableAmount
      }, 0)
      v[0].enablePos = !isFUND ? numFormat(enablePos, 0) : enablePos

      v[0].enablePosNum = v.reduce((pre: any, cur: any) => {
        return pre + cur.enableAmount
      }, 0)
      // 市值
      v[0].marketVal = v.reduce((v: any, s: any) => {
        return new Decimal(v).plus(s.marketValue).toNumber()
      }, 0)
      // 现价
      v[0].currentPriceSum = +(v
        .reduce((v: any, s: any) => {
          return new Decimal(
            parseFloat(v.currentPriceDisplay ? v.currentPriceDisplay : '0.00')
          ).plus(
            parseFloat(s.currentPriceDisplay ? s.currentPriceDisplay : '0.00')
          )
        })
        .div(v.length)
        .toFixed(isFUND ? 6 : 3, Decimal.ROUND_DOWN))
      // 成本
      v[0].CostPriceSum = +v
        .reduce((v: any, s: any) => {
          return new Decimal(v).plus(s.costPrice)
        }, 0)
        .div(v.length)
        .toFixed(isFUND ? 6 : 3, Decimal.ROUND_DOWN)
      // 盈亏
      const profitArr = v.map((item: any) => {
        return {
          profit: new Decimal(
            parseFloat(
              item.currentPriceDisplay ? item.currentPriceDisplay : '0.0'
            )
          )
            .sub(item.costPrice)
            .mul(item.currentAmount)
            .toNumber()
        }
      })
      v[0].profit = profitArr.reduce((pre: any, cur: any) => {
        return parseFloat(pre) + parseFloat(cur.profit)
      }, 0)
      //
      const CostArr = v.map((item: any) => {
        return {
          cost: new Decimal(item.costPrice).mul(item.currentAmount).toNumber()
        }
      })
      const costSum = CostArr.reduce((pre: any, cur: any) => {
        return parseFloat(pre) + parseFloat(cur.cost)
      }, 0)
      v[0].profitPercent =
        parseFloat(
          new Decimal(v[0].profit)
            .div(costSum)
            .mul(100)
            .toFixed(2, Decimal.ROUND_DOWN)
        ) + '%'
      // 仓位占比
      // v[0].percent =
      //   new Decimal(v[0].marketValue)
      //     .div(totalAssetHK)
      //     .toFixed(2, Decimal.ROUND_DOWN) + '%'
    } else {
      // 持仓&可用持仓
      const isFUND = v[0].exchangeType === 'FUND'
      v[0].currentPos = !isFUND
        ? numFormat(v[0].currentAmount, 0)
        : v[0].currentAmount
      v[0].enablePos = !isFUND
        ? numFormat(v[0].enableAmount, 0)
        : v[0].enableAmount
      v[0].currentPosNum = v[0].currentAmount
      v[0].enablePosNum = v[0].enableAmount
      // 市值
      v[0].marketVal = v[0].marketValue
      v[0].CostPriceSum = +(new Decimal(v[0].costPrice).toFixed(
        isFUND ? 6 : 3,
        Decimal.ROUND_DOWN
      ))
      v[0].currentPriceSum = +(new Decimal(
        parseFloat(v[0].currentPriceDisplay ? v[0].currentPriceDisplay : '0.0')
      ).toFixed(isFUND ? 6 : 3, Decimal.ROUND_DOWN))
      // v[0].percent =
      //   new Decimal(v[0].marketValue)
      //     .div(totalAssetHK)
      //     .toFixed(2, Decimal.ROUND_DOWN) + '%'
      // 盈亏
      v[0].profit = new Decimal(
        parseFloat(v[0].currentPriceDisplay ? v[0].currentPriceDisplay : '0.0')
      )
        .sub(v[0].costPrice)
        .mul(v[0].currentAmount)
        .toFixed(2, Decimal.ROUND_DOWN)
      v[0].profitPercent =
        v[0].costPrice === 0
          ? '--'
          : parseFloat(
              new Decimal(v[0].profit)
                .div(new Decimal(v[0].costPrice).mul(v[0].currentAmount))
                .mul(100)
                .toFixed(2, Decimal.ROUND_DOWN)
            ) + '%'
    }
    posArr.push(v[0])
  })
  return posArr
}
/*
 *@Description: 点击查询
 *@param: { newArr: 查询到所有的账户的数据, searchId : 查询的股票id }
 *@return: {*}
 */
export const search = (newArr = [], searchId: string): any => {
  const filArrMpp = new Map()
  newArr.forEach((v: any) => {
    if (!filArrMpp.has(v.stockCode)) {
      filArrMpp.set(v.stockCode, [v])
    } else {
      filArrMpp.get(v.stockCode).push(v)
    }
  })
  return filArrMpp.get(searchId) ? filArrMpp.get(searchId) : []
}
/*
 *@Description: 千分加K, 百万加M
 *@param: {*}
 *@return: {*}
 */
export function numFormatK(value: any) {
  if (value === '--') {
    return '--'
  }
  let num
  if (value > 9999 && value <= 999999) {
    num = Math.floor((value / 1000) * 1000) / 1000 + 'K'
  } else if (value <= 9999) {
    num = value
  } else if (value > 999999) {
    num = Math.floor((value / 1000000) * 1000) / 1000 + 'M'
  }
  return num
}

// 计算千-亿
export function getNumberLevel(value: number) {
  const charCn = [
    '',
    '',
    '',
    '千',
    '万',
    '十万',
    '百万',
    '千万',
    '亿',
    '十亿',
    '百亿',
    '千亿',
    '兆',
    '十兆',
    '百兆',
    '千兆',
    '京'
  ]
  const charCnIndex = Math.floor(Math.log10(value))
  return charCn[charCnIndex]
}

// 转换千分位
export function formatNumberToFinance(val: string) {
  const v = String(val)
  return v.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
// 千分位转换为number
export function formatFinanceToNumber(val: string) {
  return val.replace(/,/g, '')
}
