import { store } from '@/store'
import {
  transformSocket2,
  transformSocket3,
  transformSocket21
} from '@/utils/formatStock'
import mqtt from 'mqtt'
import * as quotType from '@/store/modules/quot/mutations-type'
import { utf8ArrayToStr } from '@/utils'
import storage, { StorageType } from '@/utils/storage'
import signWrap from '@/apollo-client/sign'
import qs from 'qs'
import { nanoid } from 'nanoid'
import router from '@/router'
import { ElMessage, ElMessageBox } from 'element-plus'
import { SubType } from '@/store/modules/quot'

class Socketer {
  socket: any
  closeStatus: boolean
  reconnectionFlag: boolean
  index: any
  ElMessageBox1: any
  constructor () {
    this.socket = null
    this.closeStatus = false
    this.reconnectionFlag = false
    this.index = 1
    this.ElMessageBox1 = ElMessageBox
  }

  init () {
    const that = this
    that.closeStatus = false
    const sessionId = JSON.parse(
      storage.rcGetItem(StorageType.local, 'sessionId')
    )
    const urlParams = qs.stringify(signWrap())
    const connectUrl = `${process.env.VUE_APP_H5_MQTT_URL}?${urlParams}`
    const options = {
      clientId: `gh_${sessionId}`,
      keepalive: 1000 * 60,
      reconnectPeriod: 0,
      username: process.env.VUE_APP_H5_MQTT_USERNAME,
      password: process.env.VUE_APP_H5_MQTT_PASSWORD
    }
    if (!this.socket) {
      this.socket = mqtt.connect(connectUrl, options)
    }

    this.socket.on('connect', () => {
      console.log('connecting')
      const topic = `login/lpoa/user/${sessionId}`
      const data = store.getters?.topic || []
      if (!data.includes(topic)) {
        this.subscribe([topic])
      }
      this.subscribe(data)
      // data.forEach((val: string) => {
      //   that.subscribe([val])
      // })
      // 重连成功改变 reconnectionFlag
      that.reconnectionFlag = true
      that.index = 1
      console.log('Connection succeeded!')
    })
    this.socket.on('error', (error: any) => {
      console.log('Connection failed', error)
    })
    this.socket.on('message', (topic: string, message: any) => {
      const result = utf8ArrayToStr(message)
      that.hanldeDataType(result)
      console.log(`Received message ${message} from topic ${topic}`)
    })
    this.socket.on('reconnect', function () {
      console.log('Reconnecting...')
    })
    this.socket.on('close', function () {
      console.log('Disconnected', that.reconnectionFlag)
      that.socket &&
        that.socket.end(true, null, function () {
          console.log('重连次数', '123')
          if (!that.reconnectionFlag) {
            that.index++
            if (that.index > 5) {
              console.log('大于五次了  => 开始关闭')
              // 关闭重连
              that.close()
              console.log('弹窗~~')
              that.ElMessageBox1.alert('', '系统页面已失效, 你已退出页面', {
                confirmButtonText: '我知道了',
                showClose: false,
                customClass: 'message-info-out',
                center: true,
                type: 'warning',
                callback: () => {
                  storage.rcRemoveItem(StorageType.local, 'sessionId')
                  storage.rcRemoveItem(StorageType.session, 'unlock')
                  router.push('/login')
                  ElMessage({
                    message: '退出登录成功！',
                    type: 'success',
                    customClass: 'custom-success'
                  })
                }
              })
              return
            }
          }
          that.reconnectionFlag = false
          that.socket = null
          // 主动关闭之后不再重连
          if (!that.closeStatus) {
            that.init()
          }
        })
    })
  }

  close () {
    console.log('%c [ 进来了 ]-118')
    const that = this
    this.closeStatus = true
    // 关闭成交推送、互踢推送
    const options = {
      type: SubType.close,
      data: []
    }
    store.commit(quotType.TOPIC, options)
    return new Promise<void>(resolve => {
      console.log('%c [ that.socket ]-118', that.socket)
      if (that.socket) {
        that.socket.end(true, null, function () {
          console.log('%c [ 关闭成功 ]-118')
          that.socket = null
          resolve()
        })
      } else {
        resolve()
      }
    })
  }

  subscribe (topic: string[]) {
    const options = {
      type: SubType.sub,
      data: topic
    }
    store.commit(quotType.TOPIC, options)
    if (this.socket) {
      this.socket.subscribe(topic, { qos: 0 }, (error: any, res: any) => {
        if (error) {
          console.log('Subscribe to topics error', error)
          return
        }
        console.log('Subscribe to topics res', res)
      })
    }
  }

  unsubscribe (topic: string[]) {
    return new Promise((resolve, reject) => {
      if (this.socket) {
        this.socket.unsubscribe(topic, function (error: any) {
          if (error) {
            reject(new Error(error))
            console.log(error)
          } else {
            const options = {
              type: SubType.unSub,
              data: topic
            }
            store.commit(quotType.TOPIC, options)
            resolve(true)
            console.log('Unsubscribed')
          }
        })
      }
    })
  }

  hanldeDataType (data: any) {
    console.log('websocket ==> 原始数据', data)
    const that = this
    if (!data) return false
    const { data: res, funId, msgType, msgGroup, msgContent } = JSON.parse(data)
    console.log('123test', JSON.parse(data))
    // 成交推送
    if (msgType) {
      switch (msgType) {
        case 'tradePush':
          // todo 成交推送逻辑
          console.log('do soming...成交推送逻辑')
          store.commit(quotType.GET_ORDERS, nanoid())
          if (JSON.parse(data).data.content) {
            ElMessage({
              message: JSON.parse(data).data.content,
              type: 'success',
              customClass: 'custom-success'
            })
          }
          break
        case 'loginOtherDevice':
          // todo 互踢
          that.close()
          // this.closeStatus = false
          storage.rcRemoveItem(StorageType.local, 'sessionId')
          storage.rcRemoveItem(StorageType.session, 'unlock')
          console.log('login other 互踢')
          ElMessageBox.alert(
            JSON.parse(data).data.msg,
            JSON.parse(data).data.title,
            {
              confirmButtonText: '确定',
              showClose: false,
              customClass: 'message-info',
              center: true,
              type: 'warning',
              callback: () => {
                router.push('/login')
                ElMessage({
                  message: '退出登录成功！',
                  type: 'success',
                  customClass: 'custom-success'
                })
              }
            }
          )
          break
        default:
          break
      }
    } else if (msgGroup === 7) {
      store.commit(quotType.CURRENCY_LIST, msgContent)
    } else if (msgGroup === 8) {
      const notice = JSON.parse(msgContent)
      const webTradeStatus = notice.clientList.filter(
        (data: any) => data.clientType === 4
      )
      if (webTradeStatus.length) {
        store.commit(quotType.GET_NOTICE_LIST, [
          {
            content: notice.content,
            title: notice.title,
            id: notice.id,
            status: notice.status,
            userType: notice.clientList[0].userType,
            clientLocations: notice.clientList[0].clientLocations
          }
        ])
      }
    } else {
      // 行情推送
      switch (funId) {
        case 2:
          store.commit(quotType.QUOT_DETAIL, { detail: transformSocket2(res) })
          break
        case 3:
          store.commit(quotType.ASKBID5, { askBid: transformSocket3(res) })
          break
        case 21:
          store.commit(quotType.ASKBID5, { askBid: transformSocket21(res) })
          break
        default:
          break
      }
    }
  }
}

export default new Socketer()
