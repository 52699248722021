import * as gql from '@/graphql'
import { baseClient } from '@/apollo-client'

export const operatorClientEntrustments = (variables: any, fetchPolicy?: any) =>
  baseClient.query({
    query: gql.operatorClientEntrustments,
    variables,
    ...fetchPolicy
  })

// 上传客户文件
export const addClientFile = (variables: any, fetchPolicy?: any) =>
  baseClient.mutate({
    mutation: gql.addClientFile,
    variables,
    ...fetchPolicy
  })

// 客户文件列表
export const getClientFiles = (variables: any, fetchPolicy?: any) =>
  baseClient.query({
    query: gql.getClientFiles,
    variables,
    ...fetchPolicy
  })

// 发送客户文件 sendClientFile
export const sendClientFile = (variables: any, fetchPolicy?: any) =>
  baseClient.mutate({
    mutation: gql.sendClientFile,
    variables,
    ...fetchPolicy
  })

// 删除客户文件
export const deleteClientFile = (variables: any, fetchPolicy?: any) =>
  baseClient.mutate({
    mutation: gql.deleteClientFile,
    variables,
    ...fetchPolicy
  })

// 操作员签署委托协议 operatorSignAgreement
export const operatorSignAgreement = (variables: any, fetchPolicy?: any) =>
  baseClient.mutate({
    mutation: gql.operatorSignAgreement,
    variables,
    ...fetchPolicy
  })

// 获取客户委托协议文件
export const clientEntrustAgreement = (variables: any, fetchPolicy?: any) =>
  baseClient.query({
    query: gql.clientEntrustAgreement,
    variables,
    ...fetchPolicy
  })

// 投资移民交易提醒
export const immTradeRemindInfo = (variables: any, fetchPolicy?: any) => {
  return baseClient.query({
    query: gql.immTradeRemindInfo,
    variables,
    ...fetchPolicy
  })
}

// 获取推送给消息
export const getPushMessages = (variables: any, fetchPolicy?: any) => {
  return baseClient.query({
    query: gql.getPushMessages,
    variables,
    ...fetchPolicy
  })
}

// setSingleMsgRead
export const setSingleMsgRead = (variables: any, fetchPolicy?: any) =>
  baseClient.mutate({
    mutation: gql.setSingleMsgRead,
    variables,
    ...fetchPolicy
  })
