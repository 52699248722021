
import { computed, defineComponent } from 'vue'
export default defineComponent({
  name: 'FargoSearch',
  props: {
    searchFormData: { type: Object, default: () => ({}) },
    searchList: { type: Array, default: () => [] }
  },
  emits: ['update:searchFormData', 'search'],
  setup(props, { emit }) {
    console.log(props)
    console.log(emit)
    const searchFormDataComputed = computed({
      get: () => props.searchFormData,
      set: val => {
        emit('update:searchFormData', val)
      }
    })
    /*
     *@Description: 传值
     *@param: {*}
     *@return: {*}
     */
    const eventFn = (val: any, item: any) => {
      console.log(val)
      console.log(item)
      // emit('update:searchFormData', props.searchFormData)
    }
    /*
     *@Description: 搜索
     *@param: {*}
     *@return: {*}
     */
    const search = (ref: string, btnType: string) => {
      emit('search', btnType)
    }
    return {
      search,
      searchFormDataComputed,
      eventFn
    }
  }
})
