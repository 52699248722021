
import {
  defineComponent,
  reactive,
  watch,
  onMounted,
  nextTick,
  toRefs,
  computed
} from 'vue'
import Sortable from 'sortablejs'
import { Decimal } from 'decimal.js'
import {
  STATES,
  ENTRUSTPROPMAP,
  ORDER_CANCEL,
  ORDER_UPDATE,
  LPOASTATE
} from '@/constant/index'
import { EntrustBs } from '@/constant/enum'
import dayjs from 'dayjs'
import { numFormat } from '@/utils/num_format'
import { ElMessageBox } from 'element-plus'
import { profitColor, mktClass } from '@/utils/color'
import unCheckedIcon from '@/assets/images/icons/unchecked.svg'
import checkedIcon from '@/assets/images/icons/checked.svg'
import disabledIcon from '@/assets/images/icons/disabled.svg'
import allCheckedIcon from '@/assets/images/icons/all_checked.svg'

export default defineComponent({
  name: 'FargoTable',
  props: {
    list: { type: Array, default: () => [] },
    tHeaderList: { type: Array, default: () => [] } as any,
    limit: { type: Number, default: 10 },
    tableLoading: { type: Boolean, default: false },
    draggableTable: { type: Boolean, default: false },
    page: { type: Number, default: 1 },
    stripe: { type: Boolean, default: true },
    height: { type: Number },
    buyHand: { type: Number, default: 20 }, // 每只股票一手的数量
    buyPrice: { type: String, default: '0' }, // 每只股票一股的价格
    isClick: { type: Boolean, default: false },
    isUpdate: { type: Boolean, default: false }, // 是否改单
    toggleIndex: { type: Number, default: 0 }
  },
  emits: [
    'change',
    'changeSelect',
    'update:list',
    'checkAll',
    'rowClick',
    'sortTable'
  ],
  setup(props, { emit }) {
    // eslint-disable-next-line
    let sortable: Sortable | null = null;
    const state = reactive({
      checkAll: false,
      disabled: false,
      dataList: props.list,
      selectedList: [],
      buttonClick: false
    })
    const handleCommand = (
      index: number | string,
      data: Record<string, any>,
      btnType: string
    ) => {
      emit('change', { data, btnType })
    }
    // 监听全选
    watch(
      () => props.list,
      (newValue) => {
        if (newValue.length) {
          const map = new Map()
          const arr1 = newValue?.filter((v: any) => v?.selectStatus)
          if (arr1.length) {
            arr1.forEach((item: any, index) => {
              item.index = index + 1
              item.tabIndexId = index + 'tabIndexId'
              map.set(item.tabIndexId, item)
            })
            props.list.forEach((v: any) => {
              if (v?.tabIndexId === map.get(v?.tabIndexId)?.tabIndexId) {
                v.indexSel = map.get(v?.tabIndexId)?.index
              }
            })
          }
          if (arr1.length === newValue.length) {
            state.checkAll = true
          } else {
            state.checkAll = false
          }
        }
      },
      { deep: true }
    )
    // 计算是否有碎股
    const brokenComputed = (val: any, selectStatus: any) => {
      // 传入两个参数 一手为多少股 能被整除就没有碎股
      // eslint-disable-next-line
      if (val == 0 && selectStatus && val !== "") {
        state.buttonClick = false
        return '<span>下单数量不能为0</span>'
      } else if (val === undefined || (val === '' && selectStatus)) {
        state.buttonClick = false
        return '<span>下单数量不能为空</span>'
      }
      if (!props.buyHand) return
      if (val % props.buyHand !== 0 && selectStatus) {
        state.buttonClick = true
        props.tHeaderList.forEach((v: any) => {
          if (v.type === 'addSub') {
            nextTick(() => {
              v.label1 = '碎股自动调整'
            })
          }
        })
        return '<span>碎股暂不支持</span>'
      }
    }
    // 计算购买力是否不足
    const buyFlag = (val: any) => {
      if (!val.num) return
      if (!props.buyPrice) return
      if (
        val.enableBalanceDisplay >
        new Decimal(val.num).mul(new Decimal(props.buyPrice)).toNumber()
      ) {
        return false
      } else if (val.selectStatus) {
        return true
      }
    }
    // 获取序号
    const getNumber = (index: any) => {
      return props.limit && props.page
        ? index + props.limit * (props.page - 1)
        : index
    }
    // 改变选中状态
    const changeCheckbox = (data: any, val: any) => {
      // 改单不可以改变选中
      if (props.isUpdate) return
      if (state.dataList) data.selectStatus = val
      emit('changeSelect', data)
    }
    // 改变全选状态
    const handleCheckAllChange = (val: boolean) => {
      console.log(val, '全选')
      // eslint-disable-next-line no-unused-expressions
      props.list?.map((v: any) => {
        v.selectStatus = val
        // eslint-disable-next-line no-unused-expressions
        v.indexSel
      })
      emit('update:list', props.list)
      emit('checkAll', val)
    }
    // 表头全选禁用
    const isDisabled = computed(() =>
      props.list?.some((item: any) => item.disabled)
    )
    // 拖拽
    const setSort = () => {
      if (!props.draggableTable) {
        return
      }
      const el = document.querySelectorAll(
        '.el-table__body-wrapper table tbody'
      )[0] as HTMLElement
      sortable = Sortable.create(el, {
        ghostClass: 'sortable-ghost',
        onEnd: (evt) => {
          if (
            typeof evt.oldIndex !== 'undefined' &&
            typeof evt.newIndex !== 'undefined'
          ) {
            props.list.splice(
              evt.newIndex,
              0,
              props.list.splice(evt.oldIndex, 1)[0]
            )
            const newArray = props.list.slice(0)
            emit('update:list', [])
            nextTick(() => {
              emit('update:list', newArray)
            })
            emit('sortTable')
          }
        }
      })
    }
    // 隔行变色
    const tableRowClassName = (rowIndex: any) => {
      if ((rowIndex.rowIndex + 1) % 2 === 0) {
        return 'warning-row'
      } else {
        return 'success-row'
      }
    }
    // 显示 操作按钮
    const findShowBtn = (arr: any, data: any) => {
      return arr?.filter(
        (i: any) =>
          i.type === 'show' ||
          (i.typeReal && i.showValue.includes(data[i.typeReal]))
      )
    }
    // 显示下级按钮
    const findShowBtns = (arr: any, data: any) => {
      return arr?.filter(
        (i: any) => i.typeReal && i.showValue.includes(data[i.typeReal])
      )
    }
    onMounted(() => {
      nextTick(() => {
        setSort()
      })
    })
    const tableCellClassName = ({
      row,
      column,
      rowIndex,
      columnIndex
    }: any) => {
      row.index = rowIndex
      column.index = columnIndex
    }
    const rowClick = (row: any, column: any, event: Event) => {
      emit('rowClick', row, column, event)
    }
    // 显示个股市场颜色
    const getColor = (value: string) => {
      if (value === 'HK') {
        return '#5577cd'
      } else if (value === 'US') {
        return '#faa040'
      } else {
        return '#FF414E'
      }
    }
    // 改单撤单按钮
    const btnUpdateColor = (value: boolean, id: number) => {
      if (value && id === 1) {
        return 'color:#ffb400;cursor: pointer;'
      } else if (value && id === 0) {
        return 'color:#386aff;cursor: pointer;'
      } else {
        return 'color:#525A75;cursor: pointer;'
      }
    }
    const showToolTip = (val: string) => {
      if (val.includes('购买力')) {
        val = val.replaceAll('(USD)', '').replaceAll('(HKD)', '')
      }
      let message = `「${val}」仅供参考，该数据或未能反映帐户的实时最大购买力，以及或未包含其他影响${val}的收入和费用等。阁下任何依赖或就该数据所作的任何决定，金马证券概不负责。请联系客户服务部（cs@igoldhorse.com）垂询实际「${val}」。`
      if (val.includes('盈亏')) {
        message = `「${val}」仅供参考，该数据或未能反映整体持仓的实时盈亏。阁下任何依赖或就该数据所作的任何决定，金马证券概不负责。请联系客户服务部（cs@igoldhorse.com）垂询实际「${val}」。`
      }
      if (val.includes('成本')) {
        message =
          '「成本价」仅供参考，成本价=（买入前的平均成本价x数量+此次买入的价格数x数量）/买入后持有数量。阁下任何依赖或就该数据所作的任何决定，金马证券概不负责。请联系客户服务部（cs@igoldhorse.com）垂询实际「成本价」。'
      }
      ElMessageBox.alert(message, '免责声明', {
        confirmButtonText: '我知道了',
        customClass: 'message-info',
        showClose: false,
        center: true,
        callback: () => {
          console.log('aaaa')
        }
      })
    }
    return {
      dayjs,
      ...toRefs(state),
      ENTRUSTPROPMAP,
      STATES,
      ORDER_CANCEL,
      ORDER_UPDATE,
      LPOASTATE,
      getNumber,
      handleCommand,
      changeCheckbox,
      handleCheckAllChange,
      setSort,
      tableRowClassName,
      brokenComputed,
      buyFlag,
      findShowBtn,
      findShowBtns,
      rowClick,
      tableCellClassName,
      numFormat,
      getColor,
      EntrustBs,
      btnUpdateColor,
      showToolTip,
      profitColor,
      isDisabled,
      unCheckedIcon,
      checkedIcon,
      disabledIcon,
      allCheckedIcon,
      mktClass
    }
  }
})
