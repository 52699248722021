/**
 * 通用字段信息配置
 */

 const HISTORY_LEN = 30 // 8

const L1_COUNT = 20

const COUNT = 36

const IPO_DARK_COUNT = 8

const EMPTY_LABEL = '--'

const UP_LABEL = '+'

const DOMN_LABEL = '-'

const SEARCH_SIZE = 30

export default {
  HISTORY_LEN,
  EMPTY_LABEL,
  UP_LABEL,
  DOMN_LABEL,
  L1_COUNT,
  COUNT,
  IPO_DARK_COUNT,
  SEARCH_SIZE
}
