
import { isExternal } from '@/utils/validate'
import { computed, defineComponent } from 'vue'
export default defineComponent({
  props: {
    iconClass: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const iconClass = props.iconClass
    const className = props.className

    const isExt = computed(() => isExternal(iconClass))
    const iconName = computed(() => `#icon-${iconClass}`)
    const svgClass = computed(() => {
      if (className) {
        return 'svg-icon ' + className
      } else {
        return 'svg-icon'
      }
    })
    const styleExternalIcon = computed(() => ({
      mask: `url(${iconClass}) no-repeat 50% 50%`,
      '-webkit-mask': `url(${iconClass}) no-repeat 50% 50%`
    }))
    return { isExt, iconName, svgClass, styleExternalIcon }
  }
})
