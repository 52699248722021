// 行情推送
export const QUOT_DETAIL = 'QUOT_DETAIL'
// 买卖五档
export const ASKBID5 = 'ASKBID5'
// 获取今日订单数据
export const GET_ORDERS = 'GET_ORDERS'
// 订阅功能号
export const TOPIC = 'TOPIC'
// 订阅汇率推送
export const CURRENCY_LIST = 'CURRENCY_LIST'
// 通知订阅
export const GET_NOTICE_LIST = 'GET_NOTICE_LIST'
