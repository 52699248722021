import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/Index.vue'

const constantFiles = require.context('./constantModules', true, /\.ts$/)
let constantModules: Array<RouteRecordRaw> = []
constantFiles.keys().forEach((key) => {
  if (key === './index.ts') return
  constantModules = constantModules.concat(constantFiles(key).default)
})

const asyncFiles = require.context('./permissionModules', true, /\.ts$/)
let permissionModules: Array<RouteRecordRaw> = []
asyncFiles.keys().forEach((key) => {
  if (key === './index.ts') return
  permissionModules = permissionModules.concat(asyncFiles(key).default)
})

export const constantRoutes: Array<RouteRecordRaw> = [
  {
    path: '/redirect',
    component: Layout,
    meta: { hidden: true },
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () =>
          import(
            /* webpackChunkName: "redirect" */ '@/views/redirect/Index.vue'
          )
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/lpoa/realTimeData',
    children: [
      {
        path: 'notice',
        component: () =>
          import(/* webpackChunkName: "notice" */ '@/views/notice/index.vue'),
        redirect: 'notice',
        meta: {
          title: '消息中心',
          icon: '',
          iconName: 'notice-icon'
        }
      }
    ]
  },
  {
    path: '/lpoa',
    component: Layout,
    redirect: 'realTimeData',
    meta: {
      title: 'LPOA',
      icon: '',
      iconName: 'lpoa'
    },
    children: [
      {
        path: 'realTimeData',
        component: () =>
          import(
            /* webpackChunkName: "real-time-data" */ '@/views/LPOA/realTimeData/index.vue'
          ),
        name: 'RealTimeData',
        meta: {
          title: '实时数据',
          noCache: true,
          affix: true
        }
      },
      {
        path: 'accountManager',
        component: () =>
          import(
            /* webpackChunkName: "account-manager" */ '@/views/LPOA/accountManager/index.vue'
          ),
        name: 'AccountManager',
        meta: {
          title: '账户管理',
          noCache: true
        }
      },
      {
        path: 'bulkTrading',
        component: () =>
          import(
            /* webpackChunkName: "bulk-trading" */ '@/views/LPOA/bulkTrading/index.vue'
          ),
        name: 'BulkTrading',
        meta: {
          title: '批量交易',
          noCache: true
        }
      },
      {
        path: 'splitOrder',
        component: () => import('@/views/LPOA/splitOrder/index.vue'),
        name: 'SplitOrder',
        meta: {
          title: '分仓交易记录',
          noCache: true
        }
      },
      {
        path: 'otc',
        component: () => import('@/views/LPOA/otc/index.vue'),
        name: 'otc',
        meta: {
          title: '柜台交易记录',
          noCache: true
        }
      },
      {
        path: 'checkDetail',
        component: () =>
          import(
            /* webpackChunkName: "check-detail" */ '@/views/LPOA/checkDetail/index.vue'
          ),
        name: 'CheckDetail',
        meta: {
          title: '查看详情',
          noCache: true,
          hidden: true
        }
      }
    ]
  },
  {
    path: '/currency',
    component: Layout,
    redirect: 'exchange',
    meta: {
      title: '货币兑换',
      icon: '',
      iconName: 'currency-icon'
    },
    children: [
      {
        path: 'exchange',
        component: () =>
          import(
            /* webpackChunkName: "exchange" */ '@/views/currency/index.vue'
          ),
        name: 'exchange',
        meta: {
          title: '兑入/兑出',
          noCache: true
        }
      },
      {
        path: 'record',
        component: () =>
          import(
            /* webpackChunkName: "exchange" */ '@/views/currency/record.vue'
          ),
        name: 'currency_record',
        meta: {
          title: '兑换记录',
          noCache: true
        }
      }
    ]
  },
  {
    path: '/notice',
    component: Layout,
    redirect: 'notice',
    meta: {
      title: '消息中心',
      icon: '',
      iconName: 'notice-icon'
    },
    children: [
      {
        path: 'list',
        component: () =>
          import(/* webpackChunkName: "notice" */ '@/views/notice/index.vue'),
        name: 'notice',
        meta: {
          title: '消息中心',
          noCache: true
        }
      }
    ]
  },
  {
    path: '/accountEntrust',
    component: Layout,
    redirect: 'entrustRecord',
    meta: {
      title: '账户委托',
      icon: '',
      iconName: 'currency-icon'
    },
    children: [
      {
        path: 'entrustRecord',
        component: () =>
          import(
            /* webpackChunkName: "entrustRecord" */ '@/views/accountEntrust/entrustRecord/index.vue'
          ),
        name: 'entrustRecord',
        meta: {
          title: '委托记录',
          noCache: true
        }
      }
    ]
  },
  ...constantModules
]

export const asyncRoutes: Array<RouteRecordRaw> = [
  // 设置权限使用的路由
  ...permissionModules
]
const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes
})

export function resetRouter() {
  const newRouter = router;
  (router as any).matcher = (newRouter as any).matcher // reset router
}

export default router
