import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_color_picker = _resolveComponent("el-color-picker")!

  return (_openBlock(), _createBlock(_component_el_color_picker, {
    modelValue: _ctx.theme,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.theme) = $event)),
    predefine: [
      '#409EFF',
      '#1890ff',
      '#304156',
      '#212121',
      '#11a983',
      '#13c2c2',
      '#6959CD',
      '#f5222d'
    ],
    class: "theme-picker",
    "popper-class": "theme-picker-dropdown"
  }, null, 8, ["modelValue"]))
}