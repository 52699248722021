
import { DeviceType } from '@/store/modules/app/state'
import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  reactive,
  toRefs,
  watch
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import { AppActionTypes } from '@/store/modules/app/action-types'
import { AppMain, Navbar, Settings, TagsView, Sidebar } from './components'
import RightPanel from '@/components/right_panel/Index.vue'
import Notice from './components/notice/index.vue'
import resize from './resize'
import webSocket from '@/mqtt'
import { useMutation } from '@vue/apollo-composable'
import * as gql from '@/graphql'
import { ElMessageBox, ElMessage } from 'element-plus'
import storage, { StorageType } from '@/utils/storage'
import dayjs from 'dayjs'
export default defineComponent({
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
    RightPanel,
    Settings,
    Sidebar,
    TagsView,
    Notice
  },
  setup() {
    const messageBox = ElMessageBox
    const { t } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const {
      sidebar,
      device,
      addEventListenerOnResize,
      resizeMounted,
      removeEventListenerResize,
      watchRouter
    } = resize()
    const state = reactive({
      handleClickOutside: () => {
        store.dispatch(AppActionTypes.ACTION_CLOSE_SIDEBAR, false)
      },
      compareTimer: '' as any,
      requestTimer: '' as any,
      timerFlag: true,
      timerFlag1: false,
      mouseFlag: true,
      extendSessionFlag: true,
      dataStr: '' as any,
      pwdDialog: false,
      managerId: JSON.parse(
        storage.rcGetItem(StorageType.local, 'userInfo') || '""'
      ),
      showMarquee: true
    })
    const classObj = computed(() => {
      return {
        hideSidebar: !sidebar.value.opened,
        openSidebar: sidebar.value.opened,
        withoutAnimation: sidebar.value.withoutAnimation,
        mobile: device.value === DeviceType.Mobile
      }
    })

    const showSettings = computed(() => {
      return store.state.settings.showSettings
    })
    const showTagsView = computed(() => {
      return store.state.settings.showTagsView
    })
    const fixedHeader = computed(() => {
      return store.state.settings.fixedHeader
    })
    watchRouter()
    onBeforeMount(() => {
      addEventListenerOnResize()
    })
    const { mutate } = useMutation(gql.extendSession)
    const extendSessionFnc = () => {
      const { path } = route
      if (!path.includes('login')) {
        state.requestTimer = setInterval(() => {
          const sessionId = storage.rcGetItem(StorageType.local, 'sessionId')
          if (!sessionId) {
            console.log('没有id了')
            clearTimeout(state.requestTimer)
            return
          }
          if (!state.timerFlag) {
            return
          }
          if (!state.mouseFlag) {
            return
          }
          mutate().then((res: any) => {
            const { extendSession } = res?.data
            if (extendSession?.ok) {
              state.mouseFlag = false
              state.dataStr = new Date(
                dayjs(extendSession?.data?.expireTime).toDate()
              ).getTime()
              console.log(state.dataStr, 'state.dataStr')
            }
          })
        }, 60000)
        // 60000
      }
    }
    extendSessionFnc()
    const dobTime = () => {
      const { path } = route
      if (!path.includes('login')) {
        state.compareTimer = setInterval(() => {
          const sessionId = storage.rcGetItem(StorageType.local, 'sessionId')
          if (!sessionId) {
            console.log('倒计时回不去了')
            clearTimeout(state.compareTimer)
            return
          }
          if (state.dataStr === '') {
            return
          }
          const num = state.dataStr - new Date().getTime()
          if (num <= 60000 && num > 0) {
            if (!state.timerFlag) {
              return
            }
            state.timerFlag = false
            messageBox
              .confirm(
                '您已闲置超过29分钟, 系统将会在1分钟后自动退出, 如您想继续使用本系统, 请点击"继续"按钮',
                '提示',
                {
                  confirmButtonText: '继续',
                  cancelButtonText: '取消',
                  center: true,
                  customClass: 'message-info-change',
                  showClose: false,
                  closeOnClickModal: false,
                  type: 'warning'
                }
              )
              .then(() => {
                mutate().then((res: any) => {
                  const { extendSession } = res.data
                  if (extendSession.ok) {
                    state.mouseFlag = false
                    state.timerFlag = true
                    state.dataStr = new Date(
                      dayjs(extendSession?.data?.expireTime).toDate()
                    ).getTime()
                    console.log(state.dataStr, 'state.dataStr')
                  }
                })
              })
              .catch(() => ({}))
          }
          if (num <= 0) {
            if (state.timerFlag1) {
              return
            }
            state.timerFlag1 = true
            clearTimeout(state.compareTimer)
            clearTimeout(state.requestTimer)
            messageBox.close()
            webSocket.close()
            storage.rcRemoveItem(StorageType.local, 'sessionId')
            storage.rcRemoveItem(StorageType.session, 'unlock')
            storage.rcRemoveItem(StorageType.session, 'reminder')
            ElMessageBox.alert('', '系统页面已失效, 你已退出页面', {
              // autofocus: false,
              confirmButtonText: '我知道了',
              showClose: false,
              customClass: 'message-info-out',
              center: true,
              type: 'warning',
              callback: () => {
                router.push('/login')
                ElMessage({
                  message: '退出登录成功！',
                  type: 'success',
                  customClass: 'custom-success'
                })
              }
            })
          }
        }, 1000)
      }
    }
    dobTime()
    // 鼠标
    const extendSession = () => {
      if (!state.timerFlag) {
        state.mouseFlag = false
        return
      }
      state.mouseFlag = true
    }
    const onShowNotice = () => {
      if (storage.rcGetItem(StorageType.session, 'reminder')) {
        // 缓存有 不显示
        state.showMarquee = false
      } else {
        if (!store.getters?.noticeList?.length) {
          state.showMarquee = false
        } else {
          state.showMarquee = true
        }
      }
    }
    onMounted(() => {
      webSocket.init()
      resizeMounted()
      window.addEventListener('mousemove', extendSession)
      window.addEventListener('keydown', extendSession)
      window.addEventListener('click', extendSession)
      onShowNotice()
    })
    onUnmounted(() => {
      window.removeEventListener('mousemove', extendSession)
      window.removeEventListener('keydown', extendSession)
      window.removeEventListener('click', extendSession)
    })

    onBeforeUnmount(() => {
      removeEventListenerResize()
    })
    const revisePwd = () => {
      state.pwdDialog = true
    }
    const closeNotice = (val: boolean) => {
      state.showMarquee = val
    }
    watch(
      () => store.getters.noticeList,
      () => {
        onShowNotice()
      }
    )
    return {
      t,
      classObj,
      sidebar,
      showSettings,
      showTagsView,
      fixedHeader,
      ...toRefs(state),
      revisePwd,
      closeNotice
    }
  }
})
