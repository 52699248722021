import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
// import { useI18n } from 'vue-i18n'
import router from '@/router'
import { RouteLocationNormalized } from 'vue-router'
import { useStore } from './store'
import storage, { StorageType } from '@/utils/storage'
// import { UserActionTypes } from './store/modules/user/action-types'
import { PermissionActionType } from './store/modules/permission/action-types'
// import { ElMessage } from 'element-plus'
// import whiteList from './config/default/whitelist'
// import settings from '@/config/default/setting.config'
NProgress.configure({ showSpinner: false })
// 不登录暂时使用的方法
const store = useStore()
store.dispatch(PermissionActionType.ACTION_SET_ROUTES, [])
// 不登录暂时使用的方法 上面两行
// const getPageTitle = (key: string) => {
//   const i18n = useI18n()
//   const title = settings.title
//   const hasKey = i18n.te(`route.${key}`)
//   if (hasKey) {
//     const pageName = i18n.t(`route.${key}`)
//     return `${pageName} - ${title}`
//   }
//   return `${title}`
// }

// router.beforeEach(async(to: RouteLocationNormalized, _: RouteLocationNormalized, next: any) => {
//   // Start progress bar
//   NProgress.start()
//   const store = useStore()
//   // 确认用户是否已登录
//   if (useStore().state.user.token) {
//     if (to.path === '/login') {
//       // 如果已登录，重定向到主页
//       next({ path: '/' })
//       NProgress.done()
//     } else {
//       // 检查用户是否已获取权限角色
//       if (store.state.user.roles.length === 0) {
//         try {
//           // Note: roles must be a object array! such as: ['admin'] or ['developer', 'editor']
//           await store.dispatch(UserActionTypes.ACTION_GET_USER_INFO, undefined)
//           const roles = store.state.user.roles
//           // 根据角色生成可访问的路由图
//           store.dispatch(PermissionActionType.ACTION_SET_ROUTES, roles)
//           // 动态添加可访问路由
//           store.state.permission.dynamicRoutes.forEach((route) => {
//             router.addRoute(route)
//           })
//           // Hack:确保addRoutes是完整的
//           // 设置replace: true，这样导航就不会留下历史记录
//           next({ ...to, replace: true })
//         } catch (err) {
//           // 删除令牌并重定向到登录页面
//           store.dispatch(UserActionTypes.ACTION_RESET_TOKEN, undefined)
//           ElMessage.error(err || 'Has Error')
//           next(`/login?redirect=${to.path}`)
//           NProgress.done()
//         }
//       } else {
//         next()
//       }
//     }
//   } else {
//     // 没有 token
//     if (whiteList.indexOf(to.path) !== -1) {
//       // 在免费登录白名单，直接去
//       next()
//     } else {
//       // 其他没有访问权限的页面将被重定向到登录页面。
//       next(`/login?redirect=${to.path}`)
//       NProgress.done()
//     }
//   }
// })

// router.afterEach((to: RouteLocationNormalized) => {
//   console.log(to)
//   // Finish progress bar
//   // hack: 移除无用的进度条
//   NProgress.done()

//   // 设置页面标题
//   // document.title = getPageTitle(to.meta.title)
// })

router.beforeEach(
  (to: RouteLocationNormalized, from: RouteLocationNormalized, next: any) => {
    // Start progress bar
    NProgress.start()
    // 确认用户是否已登录
    const sessionId = storage.rcGetItem(StorageType.local, 'sessionId')
    if (sessionId) {
      if (to.path === '/login') {
        // 如果已登录，重定向到主页
        next({ path: '/lpoa/realTimeData' })
        NProgress.done()
      }
      NProgress.done()
      if (to.name === 'BulkTrading' && to.query.type) {
        to.meta.title = '改单'
      }
      next()
    } else {
      if (to.path === '/login') {
        next()
        NProgress.done()
      }
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
)
router.afterEach(() => {
  NProgress.done()
})
