
import { defineComponent, reactive, toRefs } from 'vue'

export default defineComponent({
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      }
    },
    title: {
      type: String,
      default: 'vue'
    }
  },
  setup () {
    const state = reactive({
      isActive: false
    })
    const clickTitle = () => {
      state.isActive = !state.isActive
    }
    return {
      ...toRefs(state), clickTitle
    }
  }
})
