
import { computed, defineComponent, reactive, toRefs, onUnmounted } from 'vue'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { operatorSignAgreement } from '@/apis/index'
export default defineComponent({
  props: {
    dialogVisible: { type: Boolean, default: false },
    title: { type: String, default: '' },
    phoneMessage: { type: Object, default: () => ({}) }
  },
  emits: ['update:dialogVisible', 'confirm'],
  setup(props, { emit }) {
    const router = useRouter()
    const state = reactive({
      countFlag: false,
      countNum: 60,
      code: '',
      agreementId: '',
      operatorAccount: '',
      smsCode: '',
      codeType: 'ACCEPT_AGREEMENT_SEND_SMS',
      signedEntrustment: '',
      loading: false
    })

    const open = () => {
      state.agreementId = props.phoneMessage.agreementId
      state.operatorAccount = props.phoneMessage.operatorAccount
      state.signedEntrustment = props.phoneMessage.signedEntrustment
    }

    const dialogVisibleFlag = computed({
      get() {
        return props.dialogVisible
      },
      set(val) {
        emit('update:dialogVisible', val)
      }
    })

    const handleClose = () => {
      emit('update:dialogVisible', false)
    }

    const close = () => {
      emit('update:dialogVisible', false)
      state.code = ''
    }

    const confirm = async () => {
      if (state.code === '') {
        ElMessage({
          message: '请输入验证码',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      if (state.code.length < 6) {
        ElMessage({
          message: '请输入正确的验证码',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }

      state.loading = true
      const { data } = await operatorSignAgreement({
        param: {
          agreementId: state.agreementId,
          operatorAccount: state.operatorAccount,
          smsCode: state.code,
          signedEntrustment: state.signedEntrustment,
          codeType: 'ACCEPT_AGREEMENT_CHECK_SMS'
        }
      })
      if (data?.operatorSignAgreement?.ok) {
        ElMessage.success('签署成功')
        state.code = ''
        state.countNum = 60
        state.countFlag = false
        state.loading = false
        close()
        emit('confirm', state.code)
      } else {
        ElMessage.warning(
          data?.operatorSignAgreement?.reason?.desc
        )
        state.loading = false
      }
    }

    let timer: any = null
    // 倒计时
    const countDown = async () => {
      state.countFlag = !state.countFlag
      const { data } = await operatorSignAgreement({
        param: {
          agreementId: state.agreementId,
          operatorAccount: state.operatorAccount,
          codeType: state.codeType
        }
      })
      if (data?.operatorSignAgreement?.ok) {
        ElMessage.success('验证码发送成功')
        timer = setInterval(() => {
          if (state.countNum <= 0) {
            clearInterval(timer)
            state.countFlag = !state.countFlag
            state.countNum = 60
          }
          state.countNum--
        }, 1000)
      } else {
        ElMessage.error(data?.operatorSignAgreement?.reason?.desc)
      }
    }

    onUnmounted(() => {
      timer && clearInterval(timer)
    })

    return {
      handleClose,
      confirm,
      close,
      dialogVisibleFlag,
      countDown,
      open,
      ...toRefs(state)
    }
  }
})
