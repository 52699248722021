// 盈亏颜色
export const profitColor = (val: string | number) => {
  if (!val || val === '0.00' || val === '0%' || val === '--') {
    return 'normal-color'
  }
  if (Number(val) < 0 || String(val).includes('-')) {
    return 'down-color'
  } else {
    return 'up-color'
  }
}

// 市场标识颜色
export const mktClass = (mkt: string) => {
  switch (mkt) {
    case 'HK':
      return 'hk-mkt'
    case 'US':
      return 'us-mkt'
    case 'A':
      return 'a-mkt'
    default:
      break
  }
}
