// 股票详情信息

const detailHk = '0|1|2|3|4|5|6|7|8|9|10|16|17|18|19|20|21|22|23|24|25|26|27|28|29|30|31|32|33|34|35|37|105|153'
const detailUs = '0|1|2|3|4|5|6|7|8|9|10|16|17|18|19|20|21|22|23|24|25|26|27|28|29|30|31|32|33|34|35|37|105|162|163|165'
const detailA = '0|1|2|3|4|5|6|7|8|9|10|16|17|18|19|20|21|22|23|24|25|26|27|28|29|30|31|32|33|34|35|37|105|157'

export default {
  detailHk,
  detailUs,
  detailA
}
