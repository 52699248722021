import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, Fragment as _Fragment, renderList as _renderList, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dc0ab174"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "icon",
  "aria-hidden": "true",
  "font-size": "17px"
}
const _hoisted_2 = ["xlink:href"]
const _hoisted_3 = {
  key: 1,
  class: "",
  style: {"padding-left":"37px !important"}
}
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_SidebarItemLink = _resolveComponent("SidebarItemLink")!
  const _component_sidebar_item = _resolveComponent("sidebar-item", true)!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!

  return (!_ctx.item.meta || !_ctx.item.meta.hidden)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([
      _ctx.isCollapse ? 'simple-mode' : 'full-mode',
      { 'first-level': _ctx.isFirstLevel }
    ])
      }, [
        (!_ctx.alwaysShowRootMenu && _ctx.theOnlyOneChild && !_ctx.theOnlyOneChild.children)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.theOnlyOneChild.meta)
                ? (_openBlock(), _createBlock(_component_SidebarItemLink, {
                    key: 0,
                    to: _ctx.resolvePath(_ctx.theOnlyOneChild.path)
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_menu_item, {
                        index: _ctx.resolvePath(_ctx.theOnlyOneChild.path),
                        class: _normalizeClass({ 'submenu-title-noDropdown': _ctx.isFirstLevel })
                      }, {
                        default: _withCtx(() => [
                          (_ctx.theOnlyOneChild.meta.icon)
                            ? (_openBlock(), _createElementBlock("svg", _hoisted_1, [
                                _createElementVNode("use", {
                                  "xlink:href": _ctx.theOnlyOneChild.meta.icon
                                }, null, 8, _hoisted_2)
                              ]))
                            : _createCommentVNode("", true),
                          (_ctx.theOnlyOneChild.meta.title)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.theOnlyOneChild.meta.title), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["index", "class"])
                    ]),
                    _: 1
                  }, 8, ["to"]))
                : _createCommentVNode("", true)
            ], 64))
          : (_openBlock(), _createBlock(_component_el_sub_menu, {
              key: 1,
              index: _ctx.resolvePath(_ctx.item.path)
            }, {
              title: _withCtx(() => [
                (
            _ctx.item.meta && _ctx.item.meta.iconName && _ctx.item.meta.iconName && _ctx.isCollapse
          )
                  ? (_openBlock(), _createElementBlock("i", {
                      key: 0,
                      class: _normalizeClass([_ctx.isCollapse ? 'menu-icon-coll' : '', 'menu-icon', _ctx.item.meta.iconName ? _ctx.item.meta.iconName : ''])
                    }, null, 2))
                  : _createCommentVNode("", true),
                (_ctx.item.meta && _ctx.item.meta.title && _ctx.isCollapse)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.item.meta.title), 1))
                  : _createCommentVNode("", true)
              ]),
              default: _withCtx(() => [
                (_ctx.item.children)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.item.children, (child) => {
                      return (_openBlock(), _createBlock(_component_sidebar_item, {
                        key: child.path,
                        item: child,
                        "is-collapse": _ctx.isCollapse,
                        "is-first-level": false,
                        "base-path": _ctx.resolvePath(child.path),
                        class: "nest-menu"
                      }, null, 8, ["item", "is-collapse", "base-path"]))
                    }), 128))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["index"]))
      ], 2))
    : _createCommentVNode("", true)
}