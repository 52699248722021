import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/Index.vue'

const ExampleRouter: Array<RouteRecordRaw> = [
  {
    path: '/lpoa',
    component: Layout,
    redirect: 'realTimeData',
    meta: {
      title: 'LPOA',
      icon: '#icon404'
    },
    children: [
      {
        path: 'realTimeData',
        component: () => import(/* webpackChunkName: "error-page-401" */ '@/views/LPOA/realTimeData/index.vue'),
        name: 'RealTimeData',
        meta: {
          title: '实时数据',
          noCache: true
        }
      },
      {
        path: 'accountManager',
        component: () => import(/* webpackChunkName: "error-page-404" */ '@/views/LPOA/accountManager/index.vue'),
        name: 'AccountManager',
        meta: {
          title: '账户管理',
          noCache: true
        }
      },
      {
        path: 'bulkTrading',
        component: () => import(/* webpackChunkName: "error-page-404" */ '@/views/LPOA/bulkTrading/index.vue'),
        name: 'BulkTrading',
        meta: {
          title: '批量交易',
          noCache: true
        }
      }
    ]
  }
]
// export default ExampleRouter
